import React, { useState, useEffect } from "react";

function PujaSummaryCard({ order }) {
  const [poojaname, setPoojaname] = useState("");
  const [imageurl, setImageurl] = useState("");

  let updateddate = order.selectedDate.split("T");

  useEffect(() => {
    const allpuja = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/pujaSummary"
        );
        const pujaimage = await response.json();
        const currentpuja = pujaimage.find(
          (puja) => puja.pujaId === order.pujaId
        );
        setPoojaname(currentpuja.name);
        if (currentpuja) {
          const poojaImage = currentpuja.pujaImageURL[0];
          const pujaImageArray = poojaImage.split("/");
          if (pujaImageArray.length >= 6) {
            const formattedImageUrl = `https://${pujaImageArray[2]}.s3.amazonaws.com/${pujaImageArray[3]}/${pujaImageArray[4]}/${pujaImageArray[5]}`;
            setImageurl(formattedImageUrl);
          }
        }
      } catch (error) {
        console.error("Error fetching all pujas summary", error);
      }
    };
    allpuja();
  });

  const date = new Date(order.selectedDate);
  const dayOfWeek = date.getDay();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <div className="border border-[#D9D9D9] rounded-[10px] px-8 py-4 flex flex-col gap-8 bg-[#fff1cf]">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4">
          <span className="text-[20px] max-md:text-[16px] font-inter text-[#000000] font-bold">
            Puja Summary
          </span>
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Puja on {days[dayOfWeek]}
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Order Status
          </span>
          {order.status === "SUCCESS" ? (
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#067841] font-semibold">
              Preparing for Puja
            </span>
          ) : (
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#ff4d4f] font-semibold">
              Payment Pending
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row max-md:flex-col gap-8">
        <div>
          <img
            src={imageurl}
            alt={poojaname}
            className="w-[250px] h-[200px] rounded-[10px] flex flex-1"
            style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          />
        </div>
        <div className="flex flex-col gap-4 flex-1">
          <span className="font-inter text-[20px] max-md:text-[16px] font-bold text-[#643EF9]">
            {poojaname}
          </span>
          <div className="flex flex-row gap-8">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  Date :
                </span>
                <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                  {updateddate[0]}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  Time :
                </span>
                <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                  {order.selectedTime}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  Package:
                </span>
                <div className="flex flex-col font-inter text-[16px] max-sm:text-[12px] font-medium text-[#643EF9]">
                  <div>
                    <span className="text-[#000000] font-medium">
                      Package Name :{" "}
                    </span>
                    {order.selectedPackage.name[0]}
                  </div>

                  <div>
                    <span className="text-[#000000] font-medium">
                      Package Price :{" "}
                    </span>
                    ₹ {order.selectedPackage.packagePrice}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div>
                {order.addOns.some((add) => add.quantity > 0) && (
                  <div className="flex flex-col gap-2">
                    <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                      Add Ons:
                    </span>
                    <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#643EF9]">
                      {order.addOns.map((add) => {
                        const { addonId, name, price, newPrice, quantity } =
                          add;
                        if (quantity === 0) return null;
                        return (
                          <div key={addonId}>
                            <span className="text-[#000000] font-medium">
                              {name} :
                            </span>{" "}
                            ₹ {price} ✖ {quantity} = ₹ {newPrice}
                          </div>
                        );
                      })}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-row gap-2 items-center">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  Special Requirement:
                </span>
                <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                  {order.specialRequirement}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PujaSummaryCard;
