import React from "react";

function CartGroupPujaCard({ finalgroupcartpujadata }) {
  return (
    <div
      key={finalgroupcartpujadata.pujaId}
      className="border rounded-[10px] flex flex-row max-md:flex-col max-md:p-4 justify-between items-center bg-[#fff1cf]"
    >
      <div className="flex flex-col gap-2 text-[16px] font-inter font-bold text-[#FF8213] py-4 px-4">
        <div className="text-[18px] font-inter font-semibold text-[#000000]">
          Puja Name:{" "}
          <span className="font-bold text-[#FF8213]">
            {finalgroupcartpujadata.pujaName}
          </span>
        </div>
        <div className="mb-2 text-[18px] font-inter font-semibold text-[#FF8213]">
          <span className="font-bold text-[#000000]">Time:</span>{" "}
          {finalgroupcartpujadata.pujaDate}
        </div>
        <div className="px-4 flex flex-col">
          <div className="mb-2 text-[#FF8213]">
            <span className="text-[16px] font-inter font-bold text-[#000000]">
              Temple Name:
            </span>{" "}
            {finalgroupcartpujadata.liveEvents[0].templeName}
          </div>
          <div className="mb-2 text-[#FF8213]">
            <span className="text-[16px] font-inter font-bold text-[#000000]">
              Temple Name:
            </span>{" "}
            {finalgroupcartpujadata.liveEvents[0].templeLocation}
          </div>
          <div className="mb-2 text-[#FF8213]">
            <span className="text-[16px] font-inter font-bold text-[#000000]">
              Package Name:
            </span>{" "}
            {finalgroupcartpujadata.liveEvents[0].participationOption.name}
          </div>
        </div>
      </div>
      <div className="text-[18px] font-inter font-semibold text-[#000000] pr-10 py-4">
        Price :{" "}
        <span className="font-bold text-[#FF8213]">
          ₹ {finalgroupcartpujadata.totalPrice}
        </span>
      </div>
    </div>
  );
}

export default CartGroupPujaCard;
