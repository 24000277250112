import React, { useContext } from "react";
import { ContextData } from "../../context/Context";
import templeimage from "../../assets/temple.svg";
import grouptime from "../../assets/grouptime.svg";
import LazyLoad from "react-lazyload";
import groupPuja from "../../assets/grouppujaassets/groupPuja.png";

function GroupPujaCard({
  pujaId,
  pujaName,
  pujaImageUrl,
  liveEvents,
  pujaDate,
}) {
  const {
    grouppujastep,
    currentgrouppuja,
    nextgroupstep,
    setCurrentgrouppujadetail,
    setCartGroupPujaData,
  } = useContext(ContextData);

  const handlegrouppuja = async () => {
    try {
      const response = await fetch(
        "https://app.ritividhi.com/ritividhi/onlineLivePuja"
      );
      const currentgrouppuja = await response.json();

      const currentgrouppujadata = currentgrouppuja.filter(
        (group) => group.pujaId === pujaId
      )[0];

      if (!currentgrouppujadata) {
        console.error("No matching group puja found for the provided pujaId.");
        return;
      }

      const availableTemples = currentgrouppujadata?.liveEvents;

      if (availableTemples?.[0]?.addons) {
        const updatedAddOns = availableTemples[0].addons.map((addon) => ({
          ...addon,
          quantity: addon.quantity ? addon.quantity : 0,
        }));

        const addonIdToUpdate = "someAddonId";
        const newQuantity = 2;
        updatedAddOns.forEach((addon) => {
          if (addon.id === addonIdToUpdate) {
            addon.quantity = newQuantity;
          }
        });

        availableTemples[0].addOns = updatedAddOns;
        delete availableTemples[0].addons;
      }
      setCartGroupPujaData([currentgrouppujadata]);
      setCurrentgrouppujadetail([currentgrouppujadata]);
      nextgroupstep();
    } catch (error) {
      console.error("Error fetching all pujas summary", error);
    }
  };

  const pujaImage = pujaImageUrl[0].toLowerCase();
  const pujaimagearray = pujaImage.split("/");
  const groupimageurl = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;

  return (
    <>
      <div
        className="border border-2 border-[#B8F3DE] flex flex-col gap-4 max-sm:gap-0 rounded-t-[10px] pb-4 cursor-pointer w-full"
        style={{
          background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
        }}
        onClick={() => handlegrouppuja()}
      >
        <LazyLoad height={200} offset={100}>
          <img src={groupPuja} alt={pujaName} className="rounded-t-[10px]" />
        </LazyLoad>
        <div className="px-8 py-2 flex flex-col justify-center gap-2">
          <h1 className="text-[20px] max-md:text-[18px] max-sm:text-[16px] font-sans non-italic font-bold text-[#000000]">
            {pujaName}
          </h1>
          <div className="flex flex-row gap-4 items-center">
            <img src={templeimage} alt="Temple icon" />
            <span className="font-sans text-[14px] max-md:text-[14px] max-sm:text-[12px] font-normal">
              {liveEvents[0].templeName} {liveEvents[0].templeLocation}
            </span>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <img src={grouptime} alt="Time icon" />
            <span className="font-sans text-[14px] max-md:text-[14px] max-sm:text-[12px] font-normal">
              {pujaDate}
            </span>
          </div>
        </div>
      </div>
      <div className="rounded-b-[10px]">
        {currentgrouppuja < grouppujastep.length - 1 && (
          <button
            className="w-full py-6 flex justify-center text-[18px] font-bold font-sans text-[#fffffff] rounded-b-[10px] border border-1 border-[#000000] bg-gradient-to-r from-[#ff9800] via-[#ff5757] to-[#ff9800] bg-[length:400%_400%] animate-gradient"
            onClick={() => handlegrouppuja()}
          >
            Participate Now
          </button>
        )}
      </div>
    </>
  );
}

export default GroupPujaCard;
