import React from "react";

function ContactDetails() {
  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-[20px] max-lg:text-[16px] max-sm:text-[14px] font-bold font-sans text-[#000000] non-italic">
        Address
      </h1>
      <p className="text-[18px] max-lg:text-[16px] max-sm:text-[14px] font-normal font-sans text-[#000000] non-italic mb-8">
        A258, Chhatarpur Enclave Phase 2, Chattarpur Enclave, Chhatarpur, Delhi,
        New Delhi, Delhi 110074
      </p>
      <div className="text-[18px] max-lg:text-[16px] max-sm:text-[14px] font-normal font-sans text-[#000000] non-italic">
        <span className="font-bold">Phone:</span> +91 9910738229
      </div>
      <div className="text-[18px] max-lg:text-[16px] max-sm:text-[14px] font-normal font-sans text-[#000000] non-italic">
        <span className="font-bold">Email:</span> contact@ritividhi.com
      </div>
    </div>
  );
}

export default ContactDetails;
