import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import event from "../../assets/event.svg";
import calender from "../../assets/calender.svg";
import Button from "../button/Button";
import axios from "axios";
import { Space, DatePicker } from "antd";
import moment from "moment";
import Cookies from "js-cookie";

function UserProfileForm({
  closeForm,
  currentFirstName,
  currentLastName,
  currentEventName,
  currentEventDate,
  setProfileUpdated,
  currentNewsletter,
}) {
  const [firstName, setFirstName] = useState(currentFirstName || "");
  const [lastName, setLastName] = useState(currentLastName || "");
  const [newsletter, setNewsletter] = useState(currentNewsletter || false);
  const [eventName, setEventName] = useState(currentEventName || "");
  const [eventDate, setEventDate] = useState(currentEventDate || "");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
  });

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString();
      setEventDate(formattedDate);
    } else {
      setEventDate("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!firstName || firstName.length < 3) {
      newErrors.firstName = "First Name must be at least 3 characters.";
    }
    if (!lastName || lastName.length < 3) {
      newErrors.lastName = "Last Name must be at least 3 characters.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }
    const userData = {
      name: `${firstName} ${lastName}`.trim(),
      eventName,
      eventDate: eventDate,
      isNewsLetter: newsletter,
    };

    const token = Cookies.get("userToken");

    try {
      const response = await axios.put(
        "https://app.ritividhi.com/ritividhi/users/updateUser",
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProfileUpdated((prev) => !prev);
      closeForm();
      setFirstName("");
      setLastName("");
      setEventName("");
      setEventDate("");
      setNewsletter(false);
      setErrors({});
      closeForm();
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <LazyLoad height={100} offset={100}>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row justify-around max-md:flex-col max-md:gap-4">
          <div className="flex flex-col flex-1 items-start gap-2">
            <span className="font-inter text-[24px] max-md:text-[20px] text-[#000000] font-bold">
              Username
            </span>
            <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-normal">
              This will display on your profile.
            </span>
          </div>
          <div className="flex flex-col items-start flex-1 gap-2">
            <div className="flex flex-row justify-around border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            {errors.firstName && (
              <span className="text-red-500 text-sm">{errors.firstName}</span>
            )}
            <div className="flex flex-row justify-around border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            {errors.lastName && (
              <span className="text-red-500 text-sm">{errors.lastName}</span>
            )}
          </div>
        </div>

        <hr className="h-1 bg-[#C4C4C4] my-8" />

        <div className="flex flex-row justify-around max-md:flex-col max-md:gap-4">
          <div className="flex flex-col flex-1 items-start gap-2">
            <span className="font-inter text-[24px] max-md:text-[20px] text-[#000000] font-bold">
              News letter
            </span>
            <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-normal">
              Sign up for our news letter.
            </span>
          </div>
          <div className="flex flex-row flex-1 items-center gap-4">
            <input
              type="checkbox"
              id="newsletter"
              name="newsletter"
              className="size-6"
              checked={newsletter}
              onChange={(e) => setNewsletter(e.target.checked)}
            />
            <label
              htmlFor="newsletter"
              className="bg-transparent w-full text-[#000000] text-[20px] max-md:text-[16px] font-medium font-inter px-4 py-1"
            >
              Signup for our blogs/newsletters
            </label>
          </div>
        </div>

        <hr className="h-1 bg-[#C4C4C4] my-8" />

        <div className="flex flex-row justify-around max-md:flex-col max-md:gap-4">
          <div className="flex flex-col flex-1 items-start gap-2">
            <span className="font-inter text-[24px] max-md:text-[20px] text-[#000000] font-bold">
              Special Events/ Dates
            </span>
            <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-normal w-2/3 max-md:w-full">
              Share your important/special dates with us to get an exclusive
              offers
            </span>
          </div>
          <div className="flex flex-col items-start flex-1 gap-2">
            <div className="flex flex-row justify-around border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <img className="me-2" src={event} alt="event" />
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="Event Name for example Birthday, Anniversary etc."
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>
            <div className="flex flex-row items-center gap-2 border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <img src={calender} alt="calendar" className="w-6 h-6 me-6" />
              <Space direction="vertical" className="w-full">
                <DatePicker
                  onChange={handleDateChange}
                  value={eventDate ? moment(eventDate) : null}
                  size="middle"
                  className="w-full text-sm"
                  disabledDate={() => false}
                  style={{
                    width: "82%",
                    fontSize: "14px",
                  }}
                />
              </Space>
            </div>
          </div>
        </div>

        <hr className="h-1 bg-[#C4C4C4] my-8" />

        <div className="flex flex-row gap-4 justify-center">
          <Button
            label="Cancel"
            onClick={closeForm}
            style={{
              border: "2px solid black",
              backgroundColor: "#FFF1D0",
              color: "#000000",
            }}
          />
          <Button
            label="Submit"
            type="submit"
            style={{
              border: "2px solid black",
              backgroundColor: "#FFF1D0",
              color: "#000000",
            }}
          />
        </div>
      </form>
    </LazyLoad>
  );
}

export default UserProfileForm;
