import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { ContextData } from "../../context/Context";
import { NavLink } from "react-router-dom";
import Button from "../button/Button";
import LazyLoad from "react-lazyload";
import { motion } from "framer-motion";
import cross from "../../assets/cross.svg";
import ritividhilogo from "../../assets/ritividhilogo.webp";
import home from "../../assets/navbaricons/home.webp";
import pooja from "../../assets/navbaricons/pooja.webp";
import scroll from "../../assets/navbaricons/scroll.webp";
import faq from "../../assets/navbaricons/faq.webp";
import support from "../../assets/navbaricons/support.webp";
import ritividhilogonav from "../../assets/navbaricons/ritividhilogonav.webp";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import pinterest from "../../assets/pinterest.svg";
import linkedin from "../../assets/linkedin.svg";
import youtube from "../../assets/youtube.svg";
import templeservices from "../../assets/landingpageicons/templeservice.gif";
import grouppuja from "../../assets/landingpageicons/grouppuja.gif";
import profilepic from "../../assets/profilepic.webp";
import kumbh from "../../assets/mahakumbh/kumbh.png";

function NavModal() {
  const {
    openNavModal,
    handleNavClose,
    setCurrentPuja,
    setCurrentTemplePuja,
    setCurrentGroupPuja,
    setOpenloginmodal,
    loggedIn,
    scrollToAboutUs,
  } = useContext(ContextData);

  const [isExiting, setIsExiting] = useState(false);

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      handleNavClose();
      setIsExiting(false);
    }, 500);
  };

  const handleOpen = () => {
    setOpenloginmodal(true);
    handleClose();
  };

  const modalVariants = {
    hidden: { opacity: 0, x: -300 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -300 },
  };

  return (
    <Modal
      open={openNavModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate={isExiting ? "exit" : "visible"}
        transition={{ duration: 0.5 }}
        className={`flex flex-col justify-between absolute w-1/2 max-lg:w-2/5 max-md:w-3/4 max-sm:w-full h-full left-0 bg-white border-2 border-[#FF8213CC] shadow-xl px-8 py-5 overflow-y-auto max-h-screen`}
      >
        <div>
          <div className="flex flex-row justify-between items-center">
            <NavLink to="/">
              <LazyLoad>
                <img
                  src={ritividhilogo}
                  alt="ritividhilogo"
                  className="cursor-pointer size-20"
                  onClick={handleClose}
                />
              </LazyLoad>
            </NavLink>
            <LazyLoad>
              <img
                src={cross}
                alt="cross"
                className="size-6 cursor-pointer"
                onClick={handleClose}
              />
            </LazyLoad>
          </div>
          <div className="flex flex-col items-start gap-6 text-[#000000] font-sans text-[18px] font-semibold my-10 w-full">
            <NavLink
              to="/"
              onClick={handleClose}
              className="flex flex-row items-center gap-4"
            >
              <LazyLoad>
                <img src={home} alt="Home" className="size-8" />
              </LazyLoad>
              <button>Home</button>
            </NavLink>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <NavLink
              to="/product"
              onClick={handleClose}
              className="flex flex-row items-center gap-4"
            >
              <LazyLoad>
                <img src={pooja} alt="Pooja" className="size-8" />
              </LazyLoad>
              <button onClick={() => setCurrentPuja(0)}>Book a Pooja</button>
            </NavLink>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <NavLink
              to="/kumbh"
              onClick={handleClose}
              className="flex flex-row items-center gap-4"
            >
              <LazyLoad>
                <img src={kumbh} alt="kumbh" className="size-8" />
              </LazyLoad>
              <button className="text-transparent bg-gradient-to-r from-[#ff9800] via-[#ff5757] to-[#ff9800] bg-[length:400%_400%] animate-gradient bg-clip-text">
                Maha Kumbh
              </button>
            </NavLink>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <NavLink
              to="/temple"
              onClick={handleClose}
              className="flex flex-row items-center gap-4"
            >
              <LazyLoad>
                <img
                  src={templeservices}
                  alt="templeservices"
                  className="size-10"
                />
              </LazyLoad>
              <button onClick={() => setCurrentTemplePuja(0)}>
                Temple Services
              </button>
            </NavLink>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <NavLink
              to="/group"
              onClick={handleClose}
              className="flex flex-row items-center gap-4"
            >
              <LazyLoad>
                <img src={grouppuja} alt="grouppuja" className="size-10" />
              </LazyLoad>
              <button onClick={() => setCurrentGroupPuja(0)}>Group Puja</button>
            </NavLink>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <NavLink
              to="/"
              onClick={(e) => {
                scrollToAboutUs();
                handleClose();
              }}
              className="flex flex-row items-center gap-4 cursor-pointer"
            >
              <LazyLoad>
                <img
                  src={ritividhilogonav}
                  alt="RitividhiLogoNav"
                  className="size-8"
                />
              </LazyLoad>
              <button>About us</button>
            </NavLink>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <a
              href="https://blog.ritividhi.com/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}
              className="flex flex-row items-center gap-4"
            >
              <LazyLoad>
                <img src={scroll} alt="Scroll" className="size-8" />
              </LazyLoad>
              <button>Read</button>
            </a>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            {/* <NavLink
              to="/"
              onClick={handleClose}
              className="flex flex-row items-center gap-4"
            >
              <LazyLoad>
                <img src={faq} alt="FAQ" className="size-8" />
              </LazyLoad>
              <button>FAQ</button>
            </NavLink>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" /> */}
            <a
              href="https://wa.me/+919910738229"
              onClick={handleClose}
              className="flex flex-row items-center gap-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoad>
                <img src={support} alt="Support" className="size-8" />
              </LazyLoad>
              <button>Help/Support</button>
            </a>

            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            {loggedIn ? (
              <NavLink
                to="/user"
                className="flex flex-row items-center gap-4"
                onClick={handleClose}
              >
                <img
                  src={profilepic}
                  alt="Profilepic"
                  className="size-8 scale-125"
                />
                <button>Profile</button>
              </NavLink>
            ) : (
              <Button
                label="Login / Create an account"
                style={{
                  backgroundColor: "#ff7d33",
                  color: "#ffffff",
                  borderColor: "#ff7d33",
                }}
                onClick={handleOpen}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col items-start gap-4">
          <span className="text-[#000000] font-sans text-[20px] font-semibold no-warp flex-shrink-0">
            Follow Us At
          </span>
          <div className="flex flex-row items-center gap-4 wrap">
            <a
              href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61561276946747"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}
              aria-label="Visit our Facebook page"
            >
              <img src={facebook} alt="Facebook logo" className="size-8" />
            </a>

            <a
              href="https://www.instagram.com/ritividhi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}
              aria-label="Visit our Instagram page"
            >
              <img src={instagram} alt="instagram" className="size-8" />
            </a>
            {/* <img src={pinterest} alt="pinterest" className="size-8" /> */}
            <a
              href="https://www.linkedin.com/company/ritividhi"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}
              aria-label="Visit our Linkedin"
            >
              <img src={linkedin} alt="linkedin" className="size-8" />
            </a>
            <a
              href="https://www.youtube.com/@Ritividhi"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}
              aria-label="Visit our Linkedin"
            >
              <img src={youtube} alt="youtube" className="size-10" />
            </a>
          </div>
        </div>
      </motion.div>
    </Modal>
  );
}

export default NavModal;
