import React, { useEffect } from "react";
import arrowback from "../../assets/arrowback.svg";
import NavBar from "../../components/navbar/NavBar";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";

function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <NavBar />
      <div className="py-10 flex flex-col min-h-screen p-6 px-[10%]">
        <div className="flex flex-row items-center mb-10">
          <img
            src={arrowback}
            alt="arrowback"
            onClick={handleBackClick}
            className="mr-10 size-6 cursor-pointer"
          />
          <div className="font-quicksand text-[#000000] text-[25px] max-sm:text-[20px] font-bold">
            Privacy Policy
          </div>
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-2xl shadow-lg">
          <h1 className="text-3xl font-bold text-orange-500 mb-6">
            Privacy Policy
          </h1>
          <p className="text-gray-700 mb-4">Last updated: August 10, 2024</p>

          <p className="text-gray-700 mb-4">
            This Privacy Policy describes our policies and procedures on the
            collection, use, and disclosure of your information when you use our
            services and explains your privacy rights and how the law protects
            you. We use your personal data to provide and improve our services.
            By using the services, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Table of Contents
          </h2>
          <ul className="list-disc list-inside mb-6">
            <li>
              <a href="#info-collection" className="text-orange-500 underline">
                What Information Do We Collect?
              </a>
            </li>
            <li>
              <a href="#info-processing" className="text-orange-500 underline">
                How Do We Process Your Information?
              </a>
            </li>
            <li>
              <a href="#info-sharing" className="text-orange-500 underline">
                When and With Whom Do We Share Your Personal Information?
              </a>
            </li>
            <li>
              <a href="#cookies" className="text-orange-500 underline">
                Do We Use Cookies and Other Tracking Technologies?
              </a>
            </li>
            <li>
              <a href="#social-logins" className="text-orange-500 underline">
                How Do We Handle Your Social Logins?
              </a>
            </li>
            <li>
              <a
                href="#international-transfer"
                className="text-orange-500 underline"
              >
                Is Your Information Transferred Internationally?
              </a>
            </li>
            <li>
              <a href="#data-retention" className="text-orange-500 underline">
                How Long Do We Keep Your Information?
              </a>
            </li>
            <li>
              <a href="#minors" className="text-orange-500 underline">
                Do We Collect Information From Minors?
              </a>
            </li>
            <li>
              <a href="#privacy-rights" className="text-orange-500 underline">
                What Are Your Privacy Rights?
              </a>
            </li>
            <li>
              <a href="#dnt" className="text-orange-500 underline">
                Controls for Do-Not-Track Features
              </a>
            </li>
            <li>
              <a href="#policy-updates" className="text-orange-500 underline">
                Do We Make Updates to This Notice?
              </a>
            </li>
            <li>
              <a href="#contact" className="text-orange-500 underline">
                How Can You Contact Us About This Notice?
              </a>
            </li>
            <li>
              <a href="#data-review" className="text-orange-500 underline">
                How Can You Review, Update, or Delete the Data We Collect From
                You?
              </a>
            </li>
          </ul>

          <h2
            id="info-collection"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            1. What Information Do We Collect?
          </h2>
          <p className="text-gray-700 mb-4">
            <strong>Personal Information You Disclose to Us</strong>
            <br />
            In Short: We collect personal information that you provide to us.
            <br />
            We collect personal information that you voluntarily provide to us
            when you register on our services, express an interest in obtaining
            information about us or our products and services, participate in
            activities on our services, or otherwise contact us.
            <br />
            Sensitive Information: We do not process sensitive information.
            <br />
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>Information Automatically Collected</strong>
            <br />
            In Short: Some information, such as your Internet Protocol (IP)
            address and/or browser and device characteristics, is collected
            automatically when you visit our services.
            <br />
            We automatically collect certain information when you visit, use, or
            navigate our services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our services and for our
            internal analytics and reporting purposes.
            <br />
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>

          <h2
            id="info-processing"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            2. How Do We Process Your Information?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: We process your information to provide, improve, and
            administer our services, communicate with you, for security and
            fraud prevention, and to comply with the law. We may also process
            your information for other purposes with your consent.
            <br />
            We process your personal information for a variety of reasons,
            depending on how you interact with our services.
          </p>

          <h2
            id="info-sharing"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            3. When and With Whom Do We Share Your Personal Information?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
            <br />
            We may need to share your personal information in the following
            situations:
            <br />
            <strong>Business Transfers:</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
            <br />
            <strong>Affiliates:</strong> We may share your information with our
            affiliates, in which case we will require those affiliates to honour
            this privacy notice. Affiliates include our parent company and any
            subsidiaries, joint venture partners, or other companies that we
            control or that are under common control with us.
            <br />
            <strong>Business Partners:</strong> We may share your information
            with our business partners to offer you certain products, services,
            or promotions.
          </p>

          <h2
            id="cookies"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            4. Do We Use Cookies and Other Tracking Technologies?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: We may use cookies and other tracking technologies to
            collect and store your information.
            <br />
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to gather information when you interact with our
            services. Some online tracking technologies help us maintain the
            security of our services, prevent crashes, fix bugs, save your
            preferences, and assist with basic site functions.
            <br />
            We also permit third parties and service providers to use online
            tracking technologies on our services for analytics and advertising,
            including to help manage and display advertisements, tailor
            advertisements to your interests, or send abandoned shopping cart
            reminders (depending on your communication preferences).
            <br />
            Specific information about how we use such technologies and how you
            can refuse certain cookies is set out in our Cookie Notice.
          </p>

          <h2
            id="social-logins"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            5. How Do We Handle Your Social Logins?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: If you choose to register or log in to our services using
            a social media account, we may have access to certain information
            about you.
            <br />
            Our services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook or X
            logins). Where you choose to do this, we will receive certain
            profile information about you from your social media provider. The
            profile information we receive may vary depending on the social
            media provider concerned but will often include your name, email
            address, friends list, and profile picture, as well as other
            information you choose to make public on such a social media
            platform.
            <br />
            We will use the information we receive only for the purposes
            described in this privacy notice or that are otherwise made clear to
            you on the relevant services.
            <br />
            Please note that we do not control, and are not responsible for,
            other uses of your personal information by your third-party social
            media provider. We recommend that you review their privacy notice to
            understand how they collect, use, and share your personal
            information, and how you can set your privacy preferences on their
            sites and apps.
          </p>

          <h2
            id="international-transfer"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            6. Is Your Information Transferred Internationally?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: We may transfer, store, and process your information in
            countries other than your own.
            <br />
            Our servers are located in India. If you are accessing our services
            from outside India, please be aware that your information may be
            transferred to, stored, and processed by us in our facilities and by
            those third parties with whom we may share your personal
            information.
          </p>

          <h2
            id="data-retention"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            7. How Long Do We Keep Your Information?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.
            <br />
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements).
            <br />
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>

          <h2 id="minors" className="text-2xl font-semibold text-gray-800 mb-4">
            8. Do We Collect Information From Minors?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
            <br />
            We do not intentionally gather or request personal information from
            anyone under 18 years of age. Our platform and services are designed
            for individuals who are legally capable of entering into binding
            agreements under the Indian Contract Act, 1872. If you are under 18,
            you should use our platform or services only with the guidance of a
            parent, legal guardian, or another responsible adult. If you become
            aware of any data we may have collected from children under age 18,
            please contact us at contact@ritividhi.com.
          </p>

          <h2
            id="privacy-rights"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            9. What Are Your Privacy Rights?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: You may review, change, or terminate your account at any
            time, depending on your country, province, or state of residence.
            <br />
            <strong>Withdrawing Your Consent:</strong> If we are relying on your
            consent to process your personal information, which may be express
            and/or implied consent depending on the applicable law, you have the
            right to withdraw your consent at any time. You can withdraw your
            consent at any time by contacting us by using the contact details
            provided in the section How Can You Contact Us About This Notice?
            below.
            <br />
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
            <br />
            <strong>Account Information:</strong> If you wish to delete your
            account or personal information, please use the ‘Help’ section of
            the Ritividhi Platform. Please note that the retention of your
            personal information will be governed by applicable laws.
            <br />
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>

          <h2 id="dnt" className="text-2xl font-semibold text-gray-800 mb-4">
            10. Controls for Do-Not-Track Features
          </h2>
          <p className="text-gray-700 mb-4">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage, no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>

          <h2
            id="policy-updates"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            11. Do We Make Updates to This Notice?
          </h2>
          <p className="text-gray-700 mb-4">
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
            <br />
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date at the top of
            this privacy notice. If we make material changes to this privacy
            notice, we may notify you either by prominently posting a notice of
            such changes or by directly sending you a notification. We encourage
            you to review this privacy notice frequently to be informed of how
            we are protecting your information.
          </p>

          <h2
            id="contact"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            12. How Can You Contact Us About This Notice?
          </h2>
          <p className="text-gray-700 mb-4">
            If you have questions or comments about this notice, you may contact
            us by post at: contact@ritividhi.com
          </p>

          <h2
            id="data-review"
            className="text-2xl font-semibold text-gray-800 mb-4"
          >
            13. How Can You Review, Update, or Delete the Data We Collect From
            You?
          </h2>
          <p className="text-gray-700 mb-4">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            details about how we have processed it, correct inaccuracies, or
            delete your personal information. You may also have the right to
            withdraw your consent to our processing of your personal
            information. These rights may be limited in some circumstances by
            applicable law. To request to review, update, or delete your
            personal information, please fill out and submit a Data Subject
            Access Request.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Policy Updates
          </h2>
          <p className="text-gray-700 mb-4">
            Our policies may change as our business evolves. We may update, add,
            or remove parts of this Privacy Policy at any time without prior
            notice. While we will try to inform you of major changes, it’s your
            responsibility to check for updates regularly. Continued use of our
            services after changes means you accept the new terms. We will not
            make changes that reduce the protection of your personal information
            already shared with us.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicyPage;
