import React from "react";

function TemplePujaSummaryCard({ order }) {
  let updateddate = order.selectedDate.split("T");

  const date = new Date(order.selectedDate);
  const dayOfWeek = date.getDay();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <div className="border border-[#D9D9D9] rounded-[10px] px-8 py-4 flex flex-col gap-8 bg-[#fff1cf]">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4">
          <span className="text-[20px] max-md:text-[16px] font-inter text-[#000000] font-bold">
            Puja Summary
          </span>
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Puja on {days[dayOfWeek]}
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Order Status
          </span>
          {order.status === "SUCCESS" ? (
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#067841] font-semibold">
              Preparing for Puja
            </span>
          ) : (
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#ff4d4f] font-semibold">
              Payment Pending
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row max-md:flex-col gap-8">
        <div>
          <img
            src=""
            alt={order.templeName}
            className="w-[250px] h-[200px] rounded-[10px] flex flex-1"
            style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          />
        </div>
        <div className="flex flex-col gap-4 flex-1">
          <span className="font-inter text-[20px] max-md:text-[16px] font-bold text-[#643EF9]">
            {order.templeName}
          </span>
          <div className="flex flex-row gap-8">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  Date :
                </span>
                <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                  {updateddate[0]}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  Time :
                </span>
                <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                  {order.selectedTime}
                </span>
              </div>
            </div>
            {order.specialRequirement ? (
              <div className="flex flex-row gap-2 items-center">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  Special Requirement:
                </span>
                <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                  {order.specialRequirement}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplePujaSummaryCard;
