import React from "react";

function MahaKumbhTravellersDetails({ order }) {
  let updateddate = order.selectedStartDate.split("T");
  let updatedtime = order.selectedEndDate.split("T");

  let title = order.travelDetails.title;
  title = title.charAt(0).toUpperCase() + title.slice(1);

  return (
    <div className="border border-[#D9D9D9] rounded-[10px] px-16 py-6 gap-4 bg-[#fff1cf] flex flex-row justify-between max-md:items-start gap-2 mb-10">
      <div className="flex flex-col max-md:flex-col gap-2 items-start max-md:items-start">
        <span className="font-inter text-[18px] max-sm:text-[16px] font-bold text-[#000000]">
          Traveller Detail
        </span>
        <span className="font-inter text-[18px] max-md:text-[16px] font-medium text-[#643EF9]">
          {title} {order.travelDetails.firstName} {order.travelDetails.lastName}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
            Start Date :
          </span>
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
            {updateddate[0]}
          </span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
            End Date :
          </span>
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
            {updatedtime[0]}
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default MahaKumbhTravellersDetails;
