import React from "react";
import MahaKumbhHeroImage from "./sections/MahaKumbhHeroImage";
import BookContact from "./sections/BookContact";
import MahaKumbhPackageslist from "./sections/MahaKumbhPackageslist";
import Footer from "../../../components/footer/Footer";
import NavBar from "../../../components/navbar/NavBar";
import MahaKumbhForm from "../../../components/form/MahaKumbhForm";

function MahakumbhPage() {
  return (
    <>
      <NavBar />
      <MahaKumbhHeroImage />
      <BookContact />
      {/*<MahaKumbhPackageslist />*/}
      <MahaKumbhForm />
      <Footer />
    </>
  );
}

export default MahakumbhPage;
