import React, { useContext, useEffect, useState } from "react";
import { ContextData } from "../../context/Context";
import Button from "../button/Button";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";

function TempleOrderCard({ order }) {
  const {
    templeName,
    templeLocation,
    pujaId,
    orderId,
    orderDate,
    totalPrice,
    selectedDate,
    selectedTime,
    status,
  } = order;

  const { pujaorderlist, setorderdetail } = useContext(ContextData);

  let updateddate = selectedDate.split("T");
  let updatedorderdate = orderDate.split("T");
  let updatedordertime = updatedorderdate[1].split(".");

  const convertToUTC530 = () => {
    const date = new Date(
      `1970-01-01T${
        updatedordertime[0].length === 5
          ? updatedordertime[0] + ":00"
          : updatedordertime[0]
      }Z`
    );
    date.setHours(date.getHours() + 5, date.getMinutes() + 30);

    const formattedTime = date.toISOString().substr(11, 8);
    return formattedTime;
  };

  const handleOrderDetail = () => {
    const detail = pujaorderlist.find((order) => order.orderId === orderId);
    setorderdetail(detail);
  };

  const date = new Date(selectedDate);
  const dayOfWeek = date.getDay();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [poojaname, setPoojaname] = useState("");

  const handlepuja = async () => {
    try {
      const response = await fetch(
        "https://app.ritividhi.com/ritividhi/offline-puja"
      );
      const currentpuja = await response.json();
      const currentpujadata = currentpuja.find(
        (puja) => puja.pujaId === pujaId
      );
      if (currentpujadata) {
        setPoojaname(currentpujadata.name);
      }
    } catch (error) {
      console.error("Error fetching all pujas summary", error);
    }
  };

  useEffect(() => {
    handlepuja();
  }, [pujaId]);

  return (
    <div className="border rounded-[10px] flex flex-col justify-between bg-[#fff1cf]">
      <div className="flex flex-row max-lg:flex-col max-lg:gap-4 justify-between px-8 py-4 border rounded-[10px]">
        <div className="flex flex-row justify-between gap-8">
          <div className="flex flex-col gap-2">
            <span className="font-inter text-[16px] max-sm:text-[12px] font-semibold text-[#000000]">
              ORDER PLACED
            </span>
            <span className="font-inter text-[16px] max-sm:text-[12px] font-normal text-[#000000]">
              <div>
                <span className="font-semibold">Date : </span>
                {updatedorderdate[0]}
              </div>
              <div>
                <span className="font-semibold"> Time : </span>
                {convertToUTC530()}
              </div>
            </span>
          </div>
          <div className="flex flex-row gap-2 whitespace-nowrap">
            <span className="font-inter text-[16px] max-sm:text-[12px] font-semibold text-[#000000]">
              TOTAL :{" "}
            </span>
            <span className="font-inter text-[16px] max-sm:text-[12px] font-normal text-[#000000]">
              ₹ {totalPrice}
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-2">
          <div className="flex flex-row gap-2">
            <span className="font-inter text-[16px] max-sm:text-[12px] font-semibold text-[#000000]">
              Order ID :
            </span>
            <span className="font-inter text-[16px] max-sm:text-[12px] font-semibold text-[#000000]">
              {orderId}
            </span>
          </div>
          {/* <div className="flex flex-row items-center gap-4"> */}
          <Link to="/templeorderdetail" onClick={() => handleOrderDetail()}>
            <button className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#9747FF]">
              View Order Details
            </button>
          </Link>
          {/*<button className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#9747FF]">
                      Download Invoice
                    </button>*/}
          {/* </div> */}
        </div>
      </div>
      <div className="px-8 py-4 flex flex-col gap-2 w-full">
        <span className="font-inter text-[20px] max-sm:text-[16px] font-bold text-[#000000]">
          Puja on {days[dayOfWeek]}
        </span>
        <span>
          {status ? (
            <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#067841] mb-4">
              Preparing for Puja
            </span>
          ) : (
            <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#ff4d4f] mb-4">
              Payment Pending
            </span>
          )}
        </span>

        <div className="flex flex-row max-xl:flex-col justify-between gap-8">
          <div className="w-full flex flex-row max-md:flex-col gap-8 max-md:gap-2 justify-between">
            <LazyLoad height={200}>
              <img
                src=""
                alt={templeName || "Puja Image"}
                className="w-[200px] h-[200px] rounded-[10px] flex items-center flex-1"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              />
            </LazyLoad>
            <div className="flex flex-col gap-2 flex-1">
              <span className="font-inter text-[20px] max-sm:text-[16px] font-bold text-[#00000]">
                {templeName}
              </span>
              <span className="font-inter text-[18px] max-sm:text-[16px] font-bold text-[#000000]">
                Location : <span className="font-medium">{templeLocation}</span>
              </span>
              <div className="flex flex-col">
                <div className="flex flex-row items-start gap-2 mb-4 whitespace-nowrap">
                  <span className="font-inter text-[18px] max-sm:text-[16px] font-bold text-[#000000]">
                    Puja Name : <span className="font-medium">{poojaname}</span>
                  </span>
                </div>
                <div className="flex flex-row items-start gap-2">
                  <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                    Puja Date:
                  </span>
                  <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#643EF9]">
                    {updateddate[0]}
                  </span>
                </div>
                <div className="flex flex-row items-start gap-2">
                  <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                    Puja Time:
                  </span>
                  <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#643EF9]">
                    {selectedTime}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <a href="https://wa.me/+919910738229">
              <Button
                label="Contact RitiVidhi"
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  borderColor: "black",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TempleOrderCard;
