import React from "react";
import Button from "../../components/button/Button";
import aboutimage1 from "../../assets/landingpageicons/aboutimage1.webp";
import aboutimage2 from "../../assets/landingpageicons/aboutimage2.svg";
import aboutimage3 from "../../assets/landingpageicons/aboutimage3.webp";
import aboutimage4 from "../../assets/landingpageicons/aboutimage4.webp";
import Footer from "../../components/footer/Footer";

const AboutPage = () => {
  return (
    <>
      <section id="about" className="px-[8%] py-10 flex flex-col gap-8">
        <h1 className="text-[#000000] font-sans text-[32px] non-italic font-bold text-center">
          About RitiVidhi
        </h1>
        <hr className="border-t-2 border-gray-300  w-1/2 mx-auto" />

        <h1 className="text-[#000000] font-sans text-[32px] non-italic font-bold">
          Discover RitiVidhi
        </h1>
        <div className="flex flex-row max-md:flex-col gap-8 items-center h-full">
          <div className="flex flex-col gap-8 h-full flex-1">
            <h1 className="text-[#000000] font-sans text-[20px] non-italic font-semibold">
              Embrace the Divine with RitiVidhi: Your Pathway to Spiritual
              Harmony and Inner Peace
            </h1>
            <p className="text-[#000000] font-sans text-[15px] non-italic font-semibold text-justify leading-[1.5rem]">
              <span className="font-bold">Elevate Your Spiritual Journey</span>
              <br /> At RitiVidhi, we are dedicated to transforming every sacred
              moment into a profound spiritual experience. We seamlessly blend
              timeless Vedic rituals with the convenience of modern life,
              allowing you to connect with tradition in a way that resonates
              deeply. Each ritual is performed with authenticity by our highly
              trained pandits, following the scriptures to ensure every ceremony
              is meaningful. We don't just perform rituals—we create harmony,
              peace, and a deeper understanding of your spiritual path, ensuring
              that each puja you participate in becomes an enriching experience.
            </p>
          </div>
          <img
            src={aboutimage1}
            alt="aboutimage1"
            className="size-[30rem] max-md:w-full rounded-[20px] flex-1"
          />
        </div>

        <div className="flex flex-row gap-8 items-center max-md:hidden">
          <img
            src={aboutimage2}
            alt="aboutimage2"
            className="w-1/2 h-min rounded-[20px]"
          />
          <p className="text-[#000000] font-sans text-[15px] non-italic font-semibold text-justify">
            <span className="font-bold">
              Your All-in-One Spiritual Destination
            </span>
            <br /> RitiVidhi is your ultimate gateway to all things puja.
            Whether you seek offline or online pooja services, corporate puja
            arrangements, or event management, we have you covered. We also
            offer astrology consultancy and authentic samagri, providing a
            comprehensive marketplace for all your spiritual needs. Our platform
            is designed to bring together these services seamlessly, ensuring
            you experience the divine without any hassle.
          </p>
        </div>

        <div className="hidden max-md:flex max-md:flex-col max-md:gap-8 max-md:items-center">
          <p className="text-[#000000] font-sans text-[15px] non-italic font-semibold text-justify">
            <span className="font-bold">
              Your All-in-One Spiritual Destination
            </span>
            <br /> RitiVidhi is your ultimate gateway to all things puja.
            Whether you seek offline or online pooja services, corporate puja
            arrangements, or event management, we have you covered. We also
            offer astrology consultancy and authentic samagri, providing a
            comprehensive marketplace for all your spiritual needs. Our platform
            is designed to bring together these services seamlessly, ensuring
            you experience the divine without any hassle.
          </p>
          <img
            src={aboutimage2}
            alt="aboutimage2"
            className="w-full h-auto rounded-[20px]"
          />
        </div>

        <div className="flex flex-row max-md:flex-col gap-8 items-center">
          <p className="text-[#000000] font-sans text-[15px] non-italic font-semibold text-justify">
            <span className="font-bold">
              Tradition Meets Modern Convenience
            </span>
            <br /> At RitiVidhi, we believe in blending ancient rituals with the
            ease of modern life. From the comfort of your home, you can now
            participate in sacred ceremonies, explore our services, and connect
            with expert pandits who bring positivity and peace into every
            ritual. With RitiVidhi, tradition becomes a convenient and
            meaningful part of your life.
          </p>
          <img
            src={aboutimage3}
            alt="aboutimage3"
            className="w-1/2 h-auto max-md:w-full rounded-[20px]"
          />
        </div>

        <div className="flex flex-row gap-8 items-center max-md:hidden">
          <img
            src={aboutimage4}
            alt="aboutimage4"
            className="w-1/2 h-auto rounded-[20px]"
          />
          <ul className="text-[#000000] font-sans text-[15px] non-italic font-normal flex-1">
            <span className="font-bold">Why Choose RitiVidhi?</span>
            <br />
            <li>
              <span className="font-semibold">Authenticity:</span> Every puja
              follows the scriptures, performed by experienced pandits.
            </li>
            <li>
              <span className="font-semibold">Convenience:</span> Access all
              your spiritual needs in one place—both online and offline.
            </li>
            <li>
              <span className="font-semibold">Comprehensive Services:</span>{" "}
              From astrology to event management, we cater to every aspect of
              your spiritual journey.
            </li>
            <li>
              <span className="font-semibold">Deep Spiritual Connection:</span>{" "}
              We help you not just participate in rituals, but understand their
              deeper significance.
            </li>
          </ul>
        </div>

        <div className="hidden max-md:flex max-md:flex-col max-md:gap-8 max-md:items-center">
          <ul className="text-[#000000] font-sans text-[15px] non-italic font-normal flex-1">
            <span className="font-bold">Why Choose RitiVidhi?</span>
            <br />
            <li>
              <span className="font-semibold">Authenticity:</span> Every puja
              follows the scriptures, performed by experienced pandits.
            </li>
            <li>
              <span className="font-semibold">Convenience:</span> Access all
              your spiritual needs in one place—both online and offline.
            </li>
            <li>
              <span className="font-semibold">Comprehensive Services:</span>{" "}
              From astrology to event management, we cater to every aspect of
              your spiritual journey.
            </li>
            <li>
              <span className="font-semibold">Deep Spiritual Connection:</span>{" "}
              We help you not just participate in rituals, but understand their
              deeper significance.
            </li>
          </ul>
          <img
            src={aboutimage4}
            alt="aboutimage4"
            className="w-full h-auto rounded-[20px]"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutPage;
