import React from "react";
import whatsapp from "../../assets/whatsapp.svg";
import call from "../../assets/call.webp";

function Whatsapp() {
  return (
    <div className="fixed top-1/2 right-0 transform -translate-y-1/2 z-50">
      <div className="p-4 flex flex-col gap-4 border-2 border-[#FF8213] rounded-l-[10px] bg-[#eec492] max-sm:p-2">
        <a href="tel:+919910738229">
          <img
            src={call}
            alt="call"
            className="size-9 transform transition-transform duration-300 hover:scale-110 max-sm:size-6"
          />
        </a>
        <a href="https://wa.me/+919910738229">
          <img
            src={whatsapp}
            alt="whatsapp"
            className="size-10 transform transition-transform duration-300 hover:scale-110 max-sm:size-6"
          />
        </a>
      </div>
    </div>
  );
}

export default Whatsapp;
