import axios from "axios";
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { ContextData } from "../../context/Context";

function MahaKumbhForm() {
  const { queryemail, setqueryemail } = useContext(ContextData);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const [querymessage, setquerymessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setqueryemail(email);

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setPhone(phone);

    if (!validatePhone(phone)) {
      setPhoneError("Phone number must be 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  const handlequerymessage = (e) => {
    setquerymessage(e.target.value);
  };

  const handlemessage = async () => {
    let valid = true;

    if (fullName.trim() === "") {
      setNameError("Full name is required.");
      valid = false;
    } else {
      setNameError("");
    }

    if (!validateEmail(queryemail)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    }

    if (!validatePhone(phone)) {
      setPhoneError("Phone number must be 10 digits.");
      valid = false;
    }

    if (!valid) return;

    const data = {
      fullName: fullName,
      phone: phone,
      email: queryemail,
      comments: querymessage,
    };

    try {
      await axios({
        method: "post",
        url: "https://app.ritividhi.com/ritividhi/contactUs",
        data: data,
      });
      setFullName("");
      setPhone("");
      setqueryemail("");
      setquerymessage("");
      toast("Our Team will contact you soon", {
        icon: "✅",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (error) {
      console.error("Error occurred during the request", error);
    }
  };
  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="flex flex-col gap-4">
        <span className="font-inter text-[20px] max-md:text-[20px] text-[#000000] font-bold">
          Full Name
        </span>
        <div className="flex flex-row gap-4 border border-[#000000] w-full p-2 rounded-[10px]">
          <input
            type="text"
            value={fullName}
            onChange={handleFullNameChange}
            className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1 focus:outline-none"
            placeholder="Full name"
          />
        </div>
        {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
        <hr className="h-[1px] w-full bg-[#A9A9A9]" />
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-2">
            <span className="font-inter text-[20px] max-md:text-[20px] text-[#000000] font-bold">
              Email
            </span>
            <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
              <input
                type="text"
                value={queryemail}
                onChange={handleEmailChange}
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1 focus:outline-none"
                placeholder="Email"
              />
            </div>
            {emailError && (
              <p className="text-red-500 text-sm mt-1">{emailError}</p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <span className="font-inter text-[20px] max-md:text-[20px] text-[#000000] font-bold">
              Phone Number
            </span>
            <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
              <input
                type="text"
                value={phone}
                onChange={handlePhoneChange}
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1 focus:outline-none"
                placeholder="Phone Number"
              />
            </div>
            {phoneError && (
              <p className="text-red-500 text-sm mt-1">{phoneError}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
            Query Message
          </span>
          <div className="flex flex-row gap-2 border border-[#000000] p-2 rounded-[10px]">
            <textarea
              type="text"
              value={querymessage}
              onChange={handlequerymessage}
              className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-2 focus:outline-none"
              placeholder="Optional"
            />
          </div>
        </div>
        <div className="w-full flex justify-center my-6">
          <button
            className="w-fit bg-[#850101] px-12 max-sm:px-6 py-3 text-[#ffffff] rounded-[20px] text-[16px] mb-2"
            onClick={handlemessage}
          >
            Submit your Query
          </button>
        </div>
      </div>
    </div>
  );
}

export default MahaKumbhForm;
