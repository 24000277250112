import React from "react";
import mahakumbh from "../../../../assets/mahakumbh.webp";

function MahaKumbhHeroImage() {
  return (
    <div className="relative w-full h-full">
      <img
        loading="lazy"
        src={mahakumbh}
        alt="mahakumbh"
        className="w-screen h-[28rem]"
      />
      <div className="absolute px-[2%] flex flex-col justify-center items-start gap-4 bottom-10">
        <h1 className="text-[#ffffff] text-[32px] max-md:text-[22px] max-md:px-5 font-sans font-bold">
          Dive into the Spiritual Essence of <br />
          <span className="text-[#ff8213]">Maha Kumbh 2025</span>
        </h1>
      </div>
    </div>
  );
}

export default MahaKumbhHeroImage;
