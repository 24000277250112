import React, { useContext } from "react";
import { ContextData } from "../../context/Context";
import { Modal } from "@mui/material";
import cross from "../../assets/cross.svg";
import { Link } from "react-router-dom";

function ServiceSelectionModal() {
  const { serviceselectionmodal, setServiceSelectionModal } =
    useContext(ContextData);

  const handleClose = () => {
    setServiceSelectionModal(false);
  };

  return (
    <Modal
      open={serviceselectionmodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      className="bg-[#00000099]"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/4 max-lg:w-2/3 max-sm:w-full bg-[#FCF0E0] border-2 border-[#FF8213CC] shadow-xl px-4 py-2 rounded-[10px]">
        <div
          id="modal-modal-description"
          style={{ marginTop: "8px" }}
          className="max-sm:gap-4 w-full p-4 max-sm:p-0"
        >
          <div className="flex flex-col gap-2">
            <div className="w-full flex flex-row justify-between items-center gap-2">
              <h1 className="text-[#ff8213] text-[22px] max-md:text-[18px] font-sans font-bold">
                Select your Divine Path
              </h1>
              <img
                src={cross}
                alt="cross"
                className="size-8 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <div className="flex flex-row justify-between">
              <Link to="/product" onClick={() => handleClose()}>
                <button className="bg-[#ff8213] px-8 py-3 text-[#ffffff] rounded-[20px] text-[16px] mt-5">
                  Puja
                </button>
              </Link>
              <Link to="/kumbh" onClick={() => handleClose()}>
                <button className="bg-[#850101] px-8 py-3 text-[#ffffff] rounded-[20px] text-[16px] mt-5">
                  MahaKumbh
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ServiceSelectionModal;
