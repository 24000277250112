import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { ContextData } from "../../context/Context";
import arrowback from "../../assets/arrowback.svg";
import LazyLoad from "react-lazyload";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function CartModal() {
  const {
    opencartModal,
    setOpencartModal,
    handlecartClose,
    setCurrentpujadetail,
    setSpecialRequirement,
    setselectedmode,
    cartpujadata,
    setCartPujaData,
    setFinalCartPujaData,
    loggedIn,
    setOpenloginmodal,
  } = useContext(ContextData);

  const [isExiting, setIsExiting] = useState(false);

  const navigate = useNavigate();

  const handlelogin = () => {
    setOpenloginmodal(true);
  };

  const handlecart = () => {
    setFinalCartPujaData(cartpujadata[0]);
    localStorage.setItem("pujaData", JSON.stringify(cartpujadata[0]));
    setCartPujaData([]);
    setselectedmode("");
    setSpecialRequirement("");
    setCurrentpujadetail([]);
    setOpencartModal(false);
    navigate("/cart");
  };

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      handlecartClose();
      setIsExiting(false);
    }, 500);
  };

  const modalVariants = {
    hidden: { opacity: 0, x: 300 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 300 },
  };

  return (
    <Modal
      open={opencartModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate={isExiting ? "exit" : "visible"}
        transition={{ duration: 0.5 }}
        className={`flex flex-col justify-between absolute w-1/2 max-lg:w-2/3 max-md:w-3/4 max-sm:w-full h-full right-0 bg-white border-2 border-[#FF8213CC] shadow-xl overflow-y-auto max-h-screen`}
      >
        <div className="px-8">
          <div className="flex flex-row justify-between items-center mt-10">
            <div className="flex flex-row items-center gap-4 max-sm:gap-2">
              <LazyLoad height={20}>
                <img
                  src={arrowback}
                  alt="arrowback"
                  className="size-10 max-sm:size-8 cursor-pointer"
                  onClick={handleClose}
                />
              </LazyLoad>
              <span className="text-[24px] max-sm:text-[20px] font-inter font-bold text-[#000000]">
                Review
              </span>
            </div>
            {loggedIn ? (
              <div
                onClick={handlecart}
                className="border-2 border-[#FF8213] h-full rounded-[5px]"
              >
                <button className="text-[20px] max-sm:text-[16px] font-inter text-[#000000] font-bold cursor-pointer py-2 px-6 hover:shadow-customloginsign max-sm:px-3">
                  Add to Cart
                </button>
              </div>
            ) : (
              <div
                onClick={handlelogin}
                className="border-2 border-[#FF8213] h-full rounded-[5px]"
              >
                <button className="text-[20px] max-sm:text-[16px] font-inter text-[#000000] font-bold cursor-pointer py-2 px-6 hover:shadow-customloginsign max-sm:px-3">
                  Login to Continue
                </button>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <div>
              {cartpujadata.map((currentselectedpuja) => {
                const {
                  pujaId,
                  name,
                  pujaImageURL,
                  homePuja,
                  mode,
                  addOns,
                  selectedAddress,
                } = currentselectedpuja;

                const pujaImage =
                  pujaImageURL && pujaImageURL[0]
                    ? pujaImageURL[0].toLowerCase()
                    : null;
                let imageURL;

                if (pujaImage) {
                  const pujaimagearray = pujaImage.split("/");
                  imageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
                } else {
                  imageURL = "";
                }

                return (
                  <div key={pujaId}>
                    <div className="flex flex-row justify-between items-center gap-4 font-inter w-full my-5">
                      <span className="text-[#71717A] text-[20px]">Puja</span>
                      <hr className="bg-[#d4d4d8] h-[2px] w-full" />
                    </div>
                    <div className="flex flex-row gap-8">
                      <LazyLoad height={50}>
                        <img
                          src={imageURL}
                          alt={name}
                          className="size-20 rounded-[10px]"
                        />
                      </LazyLoad>
                      <div className="flex flex-col gap-2">
                        <span className="text-[18px] font-inter font-bold text-[#FF8213]">
                          {name}
                        </span>
                        <div className="flex flex-row gap-4 text-[16px] font-inter font-bold text-[#FF8213]">
                          <div>
                            <span className="font-semibold text-[#000000]">
                              Mode:
                            </span>{" "}
                            {mode}
                          </div>
                          <div>
                            <span className="font-semibold text-[#000000]">
                              Package Name:
                            </span>{" "}
                            {homePuja.name[0]}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center gap-4 font-inter w-full my-5">
                      <span className="text-[#71717A] text-[20px]">Price</span>
                      <hr className="bg-[#d4d4d8] h-[2px] w-full" />
                    </div>
                    <div className="flex flex-col gap-4 text-[16px] font-inter font-bold text-[#FF8213]">
                      <div>
                        <span className="font-semibold text-[#000000]">
                          Package Price:{" "}
                        </span>
                        ₹ {homePuja.packagePrice}
                      </div>
                      <div className="text-[16px] font-inter font-semibold text-[#FF8213]">
                        <div className="text-[#000000] mb-4">Add-Ons</div>
                        <div>
                          {addOns.map((add) => {
                            const { addonId, name, price, newPrice, quantity } =
                              add;
                            if (quantity === 0) return null;
                            return (
                              <div key={addonId}>
                                <span className="text-[#000000] font-medium">
                                  {name} :
                                </span>{" "}
                                ₹ {price} ✖ {quantity} = ₹ {newPrice}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="flex flex-col justify-between">
                        <div className="flex flex-row justify-between items-center gap-4 font-inter w-full mb-5">
                          <span className="text-[#71717A] text-[20px] whitespace-nowrap font-normal">
                            Devotee Details
                          </span>
                          <hr className="bg-[#d4d4d8] h-[2px] w-full" />
                        </div>
                        <div>
                          <div>
                            <span className="font-semibold text-[#000000]">
                              Name :{" "}
                            </span>
                            {selectedAddress.fullName}
                          </div>
                          {selectedAddress.landmark ? (
                            <div>
                              <span className="font-semibold text-[#000000]">
                                Address :{" "}
                              </span>
                              {selectedAddress.addressLine1},{" "}
                              {selectedAddress.addressLine2},{" "}
                              {selectedAddress.landmark}
                            </div>
                          ) : (
                            <div>
                              <span className="font-semibold text-[#000000]">
                                Address :{" "}
                              </span>
                              {selectedAddress.addressLine1},{" "}
                              {selectedAddress.addressLine2}
                            </div>
                          )}
                          <div>
                            <span className="font-semibold text-[#000000]">
                              Contact Number :{" "}
                            </span>
                            {selectedAddress.contactNumber}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div>
          <hr className="bg-[#d4d4d8] h-[2px] w-full" />
          <div className="text-[20px] max-sm:text-[16px] font-inter font-bold text-[#FF8213] py-5 px-8">
            <span className="font-semibold text-[#000000]">Total Price: </span>₹{" "}
            {cartpujadata[0]?.totalPrice || 0}
          </div>
        </div>
      </motion.div>
    </Modal>
  );
}

export default CartModal;
