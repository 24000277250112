import React from "react";
import domahakumbh from "../../../../../assets/mahakumbh/domahakumbh.webp";
import dontmahakumbh from "../../../../../assets/mahakumbh/dontmahakumbh.png";
import crossdont from "../../../../../assets/mahakumbh/crossdont.svg";
import tickdo from "../../../../../assets/mahakumbh/tickdo.svg";

function DoDont() {
  return (
    <div className="px-[8%] py-10 flex flex-col gap-8">
      <h1 className="font-sans text-[24px] max-sm:text-[18px] text-[#850101] font-bold mt-10">
        Do’s & Don’t
      </h1>
      <div className="flex flex-row max-md:flex-col justify-between gap-4 shadow-customcardswipe rounded-[20px] bg-[#FFF1D0] p-16 max-sm:p-8">
        <div className="flex flex-col justify-between items-center gap-4 flex-1">
          <img src={domahakumbh} alt="domahakumbh" className="h-60" />
          <img src={tickdo} alt="tickdo" className="size-20 max-sm:size-16" />
          <span className="text-[20px] text-[#026634] font-semibold font-inter max-sm:text-[16px]">
            Maintain cleanliness and follow sacred rituals
          </span>
        </div>
        <div className="flex flex-col justify-between items-center gap-4 flex-1">
          <div className="h-60 max-sm:h-20">
            <img src={dontmahakumbh} alt="dontmahakumbh" className="size-20" />
          </div>
          <img src={crossdont} alt="crossdont" className="size-20" />
          <span className="text-[20px] text-[#FF0404] font-semibold font-inter max-sm:text-[16px]">
            Avoid smoking, alcohol, and any intoxicants—they are strictly
            prohibited.
          </span>
        </div>
      </div>
    </div>
  );
}

export default DoDont;
