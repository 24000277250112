import React, { useContext, useEffect, useState } from "react";
import { ContextData } from "../../context/Context";
import CartDataCard from "./section/CartDataCard";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import CartTemplePujaCard from "./section/CartTemplePujaCard";
import CartGroupPujaCard from "./section/CartGroupPujaCard";
import NavBar from "../../components/navbar/NavBar";
import axios from "axios";
import toast from "react-hot-toast";
import remove from "../../assets/remove.webp";
import Cookies from "js-cookie";
import { load } from "@cashfreepayments/cashfree-js";

function CartPage() {
  const {
    finalcartpujadata,
    setFinalCartPujaData,
    pujatotal,
    setPujaTotal,
    templepujatotal,
    setTemplePujaTotal,
    grouppujatotal,
    setGroupPujaTotal,
    finaltemplecartpujadata,
    setFinalTempleCartPujaData,
    finalgroupcartpujadata,
    setFinalGroupCartPujaData,
    loggedIn,
    setOpenloginmodal,
    userid,
    cartSize,
  } = useContext(ContextData);

  let isMahaKumbh = false;
  let orderCurrency = "INR";

  let cashfree;
  var initializeSDK = async function () {
    cashfree = await load({
      mode: "production",
    });
  };
  initializeSDK();

  const [confirm, setconfirm] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPujaTotal(finalcartpujadata?.totalPrice || 0);
  }, [setPujaTotal, finalcartpujadata]);

  useEffect(() => {
    setTemplePujaTotal(finaltemplecartpujadata?.totalPrice || 0);
  }, [setTemplePujaTotal, finaltemplecartpujadata]);

  useEffect(() => {
    setGroupPujaTotal(finalgroupcartpujadata?.totalPrice || 0);
  }, [setGroupPujaTotal, finalgroupcartpujadata]);

  const handleremovedetail = () => {
    const removepujadetail = [];
    localStorage.setItem("pujaData", JSON.stringify(removepujadetail));
    setFinalCartPujaData(removepujadetail);
  };

  const handleremovetemplepujadetail = () => {
    const removetemplepujadetail = [];
    localStorage.setItem(
      "templepujaData",
      JSON.stringify(removetemplepujadetail)
    );
    setFinalTempleCartPujaData(removetemplepujadetail);
  };

  const handleremovegrouppujadetail = () => {
    const removegrouppujadetail = [];
    localStorage.setItem(
      "grouppujaData",
      JSON.stringify(removegrouppujadetail)
    );
    setFinalGroupCartPujaData(removegrouppujadetail);
  };

  let finalpaymentamount = pujatotal + templepujatotal + grouppujatotal;

  //console.log(finalcartpujadata);
  //console.log(JSON.stringify(finalgroupcartpujadata, null, 2));

  const handleorder = async () => {
    if (cartSize > 1) {
      toast("Cart limit reached. Remove an item to continue.", {
        id: "cart-limit-toast",
        icon: "⚠️",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      try {
        if (finalcartpujadata && finalcartpujadata.length !== 0) {
          let token = Cookies.get("userToken");
          if (!token) {
            console.error("Token is undefined or null");
            return;
          }
          const res = await axios({
            method: "post",
            url: "https://app.ritividhi.com/ritividhi/orders/createOrder",
            data: finalcartpujadata,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          let orderId = res.data.orderId || "defaultOrderId";
          let orderAmount = res.data.totalPrice || 0;
          handlepayment(orderId, orderAmount, isMahaKumbh, token);
        } else if (
          finaltemplecartpujadata &&
          finaltemplecartpujadata.length !== 0
        ) {
          let token = Cookies.get("userToken");
          if (!token) {
            console.error("Token is undefined or null");
            return;
          }
          const res = await axios({
            method: "post",
            url: "https://app.ritividhi.com/ritividhi/orders/createOrder",
            data: finaltemplecartpujadata,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          let orderId = res.data.orderId || "defaultOrderId";
          let orderAmount = res.data.totalPrice || 0;
          handlepayment(orderId, orderAmount, isMahaKumbh, token);
        } else if (
          finalgroupcartpujadata &&
          finalgroupcartpujadata.length !== 0
        ) {
          let token = Cookies.get("userToken");
          if (!token) {
            console.error("Token is undefined or null");
            return;
          }
          const res = await axios({
            method: "post",
            url: "https://app.ritividhi.com/ritividhi/orders/createOrder",
            data: finalgroupcartpujadata,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          let orderId = res.data.orderId || "defaultOrderId";
          let orderAmount = res.data.totalPrice || 0;
          handlepayment(orderId, orderAmount, isMahaKumbh, token);
        } else {
          console.error("Both carts are empty or undefined");
        }
      } catch (error) {
        console.error("Error occurred during the request", error);
      }
    }
  };

  const handlepayment = async (orderId, orderAmount, isMahaKumbh) => {
    try {
      let token = Cookies.get("userToken");
      const res = await axios({
        method: "post",
        url: "https://app.ritividhi.com/ritividhi/pay/initiate",
        data: { orderId, orderAmount, isMahaKumbh, orderCurrency },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res?.data?.paymentSessionId) {
        localStorage.setItem("pujaData", JSON.stringify([]));
        localStorage.setItem("templepujaData", JSON.stringify([]));
        localStorage.setItem("grouppujaData", JSON.stringify([]));
        let checkoutOptions = {
          paymentSessionId: res?.data?.paymentSessionId,
          redirectTarget: "_self",
        };
        cashfree.checkout(checkoutOptions);
      }

      // if (res.data?.data?.instrumentResponse?.redirectInfo?.url) {
      //   localStorage.setItem("pujaData", JSON.stringify([]));
      //   localStorage.setItem("templepujaData", JSON.stringify([]));
      //   localStorage.setItem("grouppujaData", JSON.stringify([]));
      //   window.location.href =
      //     res.data.data.instrumentResponse.redirectInfo.url;
      // } else {
      //   console.error("Redirect URL not found in the response.");
      // }
    } catch (error) {
      console.error("Error occurred during the request", error);
    }
  };

  const handlelogin = () => {
    if (finalcartpujadata && finalcartpujadata.pujaId) {
      const updatedPujaData = {
        ...finalcartpujadata,
        userId: userid,
      };
      localStorage.setItem("pujaData", JSON.stringify(updatedPujaData));
      setFinalCartPujaData(updatedPujaData);
    } else if (finaltemplecartpujadata && finaltemplecartpujadata.templeId) {
      const updatedPujaData = {
        ...finaltemplecartpujadata,
        userId: userid,
      };
      localStorage.setItem("templepujaData", JSON.stringify(updatedPujaData));
      setFinalTempleCartPujaData(updatedPujaData);
    } else if (finalgroupcartpujadata && finalgroupcartpujadata.pujaId) {
      const updatedPujaData = {
        ...finalgroupcartpujadata,
        userId: userid,
      };
      localStorage.setItem("grouppujaData", JSON.stringify(updatedPujaData));
      setFinalGroupCartPujaData(updatedPujaData);
    } else {
      console.error("No valid puja data available");
    }

    setconfirm(false);
    setOpenloginmodal(true);
  };

  const handleconfirm = () => {
    if (finalcartpujadata && finalcartpujadata.pujaId) {
      const updatedPujaData = {
        ...finalcartpujadata,
        userId: userid,
      };
      localStorage.setItem("pujaData", JSON.stringify(updatedPujaData));
      setFinalCartPujaData(updatedPujaData);
    }

    if (finaltemplecartpujadata && finaltemplecartpujadata.templeId) {
      const updatedPujaData = {
        ...finaltemplecartpujadata,
        userId: userid,
      };
      localStorage.setItem("templepujaData", JSON.stringify(updatedPujaData));
      setFinalTempleCartPujaData(updatedPujaData);
    }

    if (finalgroupcartpujadata && finalgroupcartpujadata.pujaId) {
      const updatedPujaData = {
        ...finalgroupcartpujadata,
        userId: userid,
      };
      localStorage.setItem("grouppujaData", JSON.stringify(updatedPujaData));
      setFinalGroupCartPujaData(updatedPujaData);
    }
    setconfirm(true);
  };

  return (
    <>
      <NavBar />
      {(finalcartpujadata && finalcartpujadata?.length !== 0) ||
      (finaltemplecartpujadata && finaltemplecartpujadata?.length !== 0) ||
      (finalgroupcartpujadata && finalgroupcartpujadata?.length !== 0) ? (
        <div className="px-[8%] py-10 flex flex-col gap-6">
          <div className="text-[32px] font-inter font-bold text-[#000000]">
            Cart
          </div>
          {finalcartpujadata && finalcartpujadata?.length !== 0 ? (
            <>
              <div className="text-[26px] font-inter font-bold text-[#000000]">
                Pujas
              </div>
              <div className="flex flex-col gap-8">
                <CartDataCard finalcartpujadata={finalcartpujadata} />
                <div className="flex flex-row items-center max-md:flex-col gap-2">
                  <div className="py-2 px-4 text-[16px] text-[#000000] font-sans font-bold">
                    More details will be sent to you shortly.
                  </div>
                  <div onClick={() => handleremovedetail()}>
                    <img src={remove} alt="remove" className="size-10" />
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {finaltemplecartpujadata && finaltemplecartpujadata?.length !== 0 ? (
            <>
              <div className="text-[26px] font-inter font-bold text-[#000000]">
                Temple Pujas
              </div>
              <div className="flex flex-col gap-8">
                <CartTemplePujaCard
                  finaltemplecartpujadata={finaltemplecartpujadata}
                />
                <div className="flex flex-row items-center max-md:flex-col gap-2">
                  <div className="py-2 px-4 text-[16px] text-[#000000] font-sans font-bold">
                    More details will be sent to you shortly.
                  </div>
                  <div onClick={() => handleremovetemplepujadetail()}>
                    <img src={remove} alt="remove" className="size-10" />
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {finalgroupcartpujadata && finalgroupcartpujadata?.length !== 0 ? (
            <>
              <div className="text-[26px] font-inter font-bold text-[#000000]">
                Group Pujas
              </div>
              <div className="flex flex-col gap-8">
                <CartGroupPujaCard
                  finalgroupcartpujadata={finalgroupcartpujadata}
                />
                <div className="flex flex-row items-center max-md:flex-col gap-2">
                  <div className="py-2 px-4 text-[16px] text-[#000000] font-sans font-bold">
                    More details will be sent to you shortly.
                  </div>
                  <div onClick={() => handleremovegrouppujadetail()}>
                    <img src={remove} alt="remove" className="size-10" />
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="text-[18px] font-inter font-semibold text-[#000000] w-full flex justify-end max-md:justify-between">
            Total Cost :{" "}
            <span className=" font-bold text-[#FF8213]">
              ₹ {finalpaymentamount}
            </span>
          </div>
          {loggedIn ? (
            <div className="w-full flex justify-center">
              {confirm ? (
                <Button
                  label="Pay"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    borderColor: "#FF8213",
                  }}
                  onClick={handleorder}
                />
              ) : (
                <Button
                  label="Confirm Order"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    borderColor: "#FF8213",
                  }}
                  onClick={handleconfirm}
                />
              )}
            </div>
          ) : (
            <div className="w-full flex justify-center">
              <Button
                label="Login to Continue"
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  borderColor: "#FF8213",
                }}
                onClick={handlelogin}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] text-center max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Embrace the Divine with RitiVidhi’s Sacred Rituals! <br />
            Add Pujas to get blessing
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default CartPage;
