import React, { useEffect } from "react";
import arrowback from "../../assets/arrowback.svg";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";

function TermOfServicePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <NavBar />
      <div className="py-10 flex flex-col min-h-screen p-6 px-[10%]">
        <div className="flex flex-row items-center mb-10">
          <img
            src={arrowback}
            alt="arrowback"
            onClick={handleBackClick}
            className="mr-10 size-6 cursor-pointer"
          />
          <div className="font-quicksand text-[#000000] text-[25px] max-sm:text-[20px] font-bold">
            Terms & Conditions
          </div>
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-2xl shadow-lg">
          <h1 className="text-3xl font-bold text-orange-500 mb-4">
            Terms & Conditions
          </h1>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              1. Introduction
            </h2>
            <p className="text-gray-600">
              This document is an electronic record in terms of the Information
              Technology Act, 2000 and rules thereunder as applicable, and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
            </p>
            <p className="text-gray-600 mt-2">
              This document is published in accordance with the provisions of
              Rule 3(1) of the Information Technology (Intermediaries
              guidelines) Rules, 2011, which require publishing the rules and
              regulations, privacy policy, and Terms of Use for access or usage
              of domain name ritividhi.com, including the related mobile site
              and mobile application (hereinafter referred to as the
              “Platform”).
            </p>
            <p className="text-gray-600 mt-2">
              The Platform is owned by Aiviktek Consulting Private Limited, a
              company incorporated under the Companies Act, 1956, with its
              registered office at SHESH MANI TIWARI R K, PURAM HAVELIYA JHUNSI,
              Jhunsi, Allahabad, Allahabad- 211019, Uttar Pradesh.
            </p>
            <p className="text-gray-600 mt-2">
              Your use of the Platform and services and tools is governed by the
              following terms and conditions as applicable to the Platform,
              including the applicable policies which are incorporated herein by
              way of reference. If You transact on the Platform, You shall be
              subject to the policies that are applicable to the Platform for
              such transactions. By mere use of the Platform, You shall be
              contracting with the Platform Owner, and these terms and
              conditions, including the policies, constitute Your binding
              obligations with the Platform Owner.
            </p>
            <p className="text-gray-600 mt-2">
              These Terms of Use relate to your use of our website, goods (as
              applicable), or services (as applicable) (collectively,
              “Services”). Any terms and conditions proposed by You which are in
              addition to or which conflict with these Terms of Use are
              expressly rejected by the Platform Owner and shall be of no force
              or effect. These Terms of Use can be modified at any time without
              assigning any reason. It is your responsibility to periodically
              review these Terms of Use to stay informed of updates.
            </p>
            <p className="text-gray-600 mt-2">
              For the purpose of these Terms of Use, wherever the context so
              requires, "you", “your”, or "user" shall mean any natural or legal
              person who has agreed to become a user/buyer on the Platform.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              2. Acceptance of Terms
            </h2>
            <p className="text-gray-600">
              ACCESSING, BROWSING, OR OTHERWISE USING THE PLATFORM INDICATES
              YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS
              OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE
              PROCEEDING.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              3. User Obligations
            </h2>
            <p className="text-gray-600">
              To access and use the Services, you agree to provide true,
              accurate, and complete information to us during and after
              registration, and you shall be responsible for all acts done
              through the use of your registered account on the Platform.
            </p>
            <p className="text-gray-600 mt-2">
              You agree not to use the Platform and/or Services for any purpose
              that is unlawful, illegal, or forbidden by these Terms, or Indian
              or local laws that might apply to you.
            </p>
            <p className="text-gray-600 mt-2">
              You agree and acknowledge that the website and the Services may
              contain links to other third-party websites. On accessing these
              links, you will be governed by the terms of use, privacy policy,
              and other policies of such third-party websites. These links are
              provided for your convenience to provide further information.
            </p>
            <p className="text-gray-600 mt-2">
              You understand that upon initiating a transaction for availing the
              Services, you are entering into a legally binding and enforceable
              contract with the Platform Owner for the Services.
            </p>
            <p className="text-gray-600 mt-2">
              You shall indemnify and hold harmless the Platform Owner, its
              affiliates, group companies (as applicable), and their respective
              officers, directors, agents, and employees, from any claim or
              demand, or actions including reasonable attorneys’ fees, made by
              any third party or penalty imposed due to or arising out of Your
              breach of this Terms of Use, privacy Policy and other Policies, or
              Your violation of any law, rules or regulations or the rights
              (including infringement of intellectual property rights) of a
              third party.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              4. No Warranties and Liability
            </h2>
            <p className="text-gray-600">
              Neither we nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness, or
              suitability of the information and materials offered on this
              website or through the Services, for any specific purpose. You
              acknowledge that such information and materials may contain
              inaccuracies or errors and we expressly exclude liability for any
              such inaccuracies or errors to the fullest extent permitted by
              law.
            </p>
            <p className="text-gray-600 mt-2">
              Your use of our Services and the Platform is solely and entirely
              at your own risk and discretion, for which we shall not be liable
              to you in any manner. You are required to independently assess and
              ensure that the Services meet your requirements.
            </p>
            <p className="text-gray-600 mt-2">
              The contents of the Platform and the Services are proprietary to
              us and are licensed to us. You will not have any authority to
              claim any intellectual property rights, title, or interest in its
              contents. The contents include, but are not limited to, the
              design, layout, look, and graphics.
            </p>
            <p className="text-gray-600 mt-2">
              Unauthorised use of the Platform and/or the Services may lead to
              action against you as per these Terms of Use and/or applicable
              laws.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              5. Payment Terms
            </h2>
            <p className="text-gray-600">
              You agree to pay us the charges associated with availing of the
              Services.
            </p>
            <p className="text-gray-600 mt-2">
              Any payment-related issues, including those pertaining to the
              accuracy of billing, should be reported immediately. Failure to do
              so may result in an inability to dispute the charges.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              6. Use of Services (Specific to Puja Services)
            </h2>
            <p className="text-gray-600">
              Our Platform provides access to a range of puja services that may
              involve third-party service providers. We ensure that these
              service providers are vetted; however, we do not take
              responsibility for their actions or omissions.
            </p>
            <p className="text-gray-600 mt-2">
              By booking a puja service through our Platform, you acknowledge
              that the performance of such services depends on several factors,
              including the availability of specific priests and materials, and
              we cannot guarantee the outcome or the fulfillment of religious
              intentions.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              7. Third-Party Links
            </h2>
            <p className="text-gray-600">
              The Platform may contain links to third-party websites or
              resources. You acknowledge and agree that we are not responsible
              or liable for the availability, accuracy, content, or policies of
              third-party websites or resources. Links to such websites or
              resources do not imply any endorsement by or affiliation with us.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              8. Limitation of Liability
            </h2>
            <p className="text-gray-600">
              In no event will the Platform Owner be liable for any indirect,
              consequential, incidental, special, or punitive damages, including
              without limitation damages for loss of profits or revenues,
              business interruption, loss of business opportunities, loss of
              data, or loss of other economic interests, whether in contract,
              negligence, tort, or otherwise, arising from the use of or
              inability to use the Services, however caused, and whether arising
              in contract, tort, negligence, warranty, or otherwise, exceed the
              amount paid by You for using the Services giving rise to the cause
              of action or Rupees One Hundred (Rs. 100), whichever is less.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              9. Force Majeure
            </h2>
            <p className="text-gray-600">
              Notwithstanding anything contained in these Terms of Use, the
              parties shall not be liable for any failure to perform an
              obligation under these Terms if performance is prevented or
              delayed by a force majeure event.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              10. Governing Law and Jurisdiction
            </h2>
            <p className="text-gray-600">
              These Terms and any dispute or claim relating to them, or their
              enforceability, shall be governed by and construed in accordance
              with the laws of India.
            </p>
            <p className="text-gray-600 mt-2">
              All disputes arising out of or in connection with these Terms
              shall be subject to the exclusive jurisdiction of the courts in
              Kushinagar, Uttar Pradesh.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              11. Contact Information
            </h2>
            <p className="text-gray-600">
              All concerns or communications relating to these Terms must be
              communicated to us using the contact information provided on this
              website.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermOfServicePage;
