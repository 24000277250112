import React from "react";

function TemplePujaAddressCard({ order }) {
  let updateddate = order.selectedDate.split("T");

  return (
    <div className="border border-[#D9D9D9] rounded-[10px] px-16 py-6 gap-4 bg-[#fff1cf] flex flex-row justify-between max-md:items-start gap-2 mb-10">
      <div className="flex flex-col max-md:flex-col gap-2 items-start max-md:items-start">
        <span className="font-inter text-[18px] max-sm:text-[16px] font-bold text-[#000000]">
          Prasāda will be delivered to this address
        </span>
        <span className="font-inter text-[18px] max-md:text-[16px] font-medium text-[#643EF9]">
          {order.selectedAddress.addressLine1}{" "}
          {order.selectedAddress.addressLine2} {order.selectedAddress.landMark}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
            Puja Date :
          </span>
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
            {updateddate[0]}
          </span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
            Puja Time :
          </span>
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
            {order.selectedTime}
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default TemplePujaAddressCard;
