import React from "react";

function CartTemplePujaCard({ finaltemplecartpujadata }) {
  return (
    <div
      key={finaltemplecartpujadata.templeId}
      className="border rounded-[10px] flex flex-row max-md:flex-col max-md:p-4 justify-between items-center bg-[#fff1cf]"
    >
      <div className="flex flex-col gap-4 max-md:flex-col">
        <div className="flex flex-row max-md:flex-col gap-4 items-center">
          <img
            src=""
            alt={finaltemplecartpujadata.templeName}
            className="size-40 rounded-[10px]"
          />
          <div className="flex flex-col gap-4 text-[16px] font-inter font-bold text-[#FF8213] py-4">
            <div>
              <div className="text-[18px] font-inter font-bold text-[#FF8213]">
                {finaltemplecartpujadata.templeName}
              </div>
              <div className="text-[18px] font-inter font-bold text-[#FF8213]">
                {finaltemplecartpujadata.templeLocation}
              </div>
            </div>
            <div>
              {finaltemplecartpujadata.pujas.map((item, index) => {
                const { name, mode, homePuja } = item;
                return (
                  <div
                    className="flex flex-row justify-between items-center"
                    key={index}
                  >
                    <div className="flex flex-col gap-2 text-[16px] font-inter font-bold text-[#FF8213] py-4">
                      <div className="text-[18px] font-inter font-semibold text-[#000000]">
                        Puja Name:{" "}
                        <span className=" font-bold text-[#FF8213]">
                          {name}
                        </span>
                      </div>
                      <div>
                        <span className="font-semibold text-[#000000]">
                          Mode:
                        </span>{" "}
                        {mode}
                      </div>
                      {/*<div className="mb-2">
                        <span className="font-semibold text-[#000000]">
                          Package Name:
                        </span>{" "}
                        {homePuja.name[0]}
                      </div>*/}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="text-[18px] font-inter font-semibold text-[#000000] pr-10 py-4">
        Price :{" "}
        <span className="font-bold text-[#FF8213]">
          ₹ {finaltemplecartpujadata.totalPrice}
        </span>
      </div>
    </div>
  );
}

export default CartTemplePujaCard;
