import React, { useContext, useEffect, useState } from "react";
import backgroundPricing from "../../../../../assets/mahakumbh/backgroundPricing.webp";
import check from "../../../../../assets/check.webp";
import { ContextData } from "../../../../../context/Context";
import MahaKumbhCalender from "../../../../../components/modals/MahaKumbhCalender";
import MahaKumbhRoomsModal from "../../../../../components/modals/MahaKumbhRoomsModal";

function Pricing({ mahakumbhpackagedata }) {
  const { setMahaKumbhCalendermodal, setConfirm } = useContext(ContextData);
  const [alloweddates, setalloweddates] = useState([]);

  useEffect(() => {
    if (mahakumbhpackagedata?.package_dates) {
      const allDates = mahakumbhpackagedata.package_dates.map(
        (item) => item.date
      );
      setalloweddates(allDates);
    }
  }, [mahakumbhpackagedata]);

  const handlePricing = () => {
    setMahaKumbhCalendermodal(true);
    setConfirm(false);
  };

  return (
    <>
      {mahakumbhpackagedata && mahakumbhpackagedata.length !== 0 ? (
        <>
          <div className="px-[8%] py-10 flex flex-col gap-8">
            <h1 className="font-sans text-[24px] max-sm:text-[18px] text-[#850101] font-bold mt-10">
              Pricing
            </h1>
            <div className="w-full flex justify-center">
              <div className="relative shadow-custommahakumbh h-[40rem] w-[28rem] max-sm:h-[28rem]">
                <img
                  src={backgroundPricing}
                  alt="backgroundImage"
                  className="h-[40rem] w-[28rem] max-sm:h-[28rem]"
                />
                <div className="absolute bg-[#FCF3DC] top-4 left-4 max-sm:left-3.5 h-[38rem] w-[26rem] max-sm:w-[18rem] max-sm:h-[26rem] px-8 py-4 rounded-[20px]">
                  <div className="flex flex-col justify-between items-center gap-2 h-full">
                    <p className="text-[14px] text-[#850101] font-medium font-inter">
                      "A Spiritual Experience, Simplified"
                    </p>
                    <div>
                      <h2 className="text-[24px] text-[#850101] font-bold font-inter">
                        {mahakumbhpackagedata.packageType[0]}
                      </h2>
                      <h1 className="text-[26px] text-[#850101] font-extrabold font-inter max-sm:text-[22px]">
                        ₹ {mahakumbhpackagedata.pricing.basePrice}
                      </h1>
                      <span className="text-[16px] text-[#850101] font-bold font-inter">
                        Per Person
                      </span>
                    </div>
                    <p className="text-[14px] text-[#850101] font-medium font-inter text-justify">
                      Experience a sacred dip with expert pandits, personalized
                      samagri, reserved access, auspicious timing, and blessed
                      prasad.
                    </p>
                    <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2 block max-sm:hidden">
                      {mahakumbhpackagedata.packageDescriptionPoints.map(
                        (descriptionpoints, index) => {
                          return (
                            <div
                              key={index}
                              className="flex flex-row gap-2 items-center"
                            >
                              <img src={check} alt="check" className="size-4" />
                              <div className="text-[14px] font-medium text-[#000000] font-inter">
                                {descriptionpoints}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    {/* <div className="hidden max-sm:block text-[14px] text-[#850101] font-bold font-inter">
                      More Details
                    </div> */}
                    <div className="flex flex-col items-center gap-2 max-sm:gap-0">
                      <button
                        className="bg-[#850101] px-12 max-sm:px-6 py-3 text-[#ffffff] rounded-[20px] text-[16px] my-2"
                        onClick={handlePricing}
                      >
                        Select {mahakumbhpackagedata.packageType[0]} Package
                      </button>
                      {/*<span className="text-[14px] text-[#000000] font-semibold font-inter">
                        Cancellation Policy
                      </span>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MahaKumbhCalender {...{ alloweddates }} />
          <MahaKumbhRoomsModal />
        </>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading your Puja...
          </div>
        </div>
      )}
    </>
  );
}

export default Pricing;
