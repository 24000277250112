import React from "react";
import Logo from "./footercomponents/Logo";
import SubscribeInput from "./footercomponents/SubscribeInput";
import Navigation from "./footercomponents/Navigation";
import Policies from "./footercomponents/Policies";
import Socials from "./footercomponents/Socials";
import ContactDetails from "./footercomponents/ContactDetails";

function Footer() {
  return (
    <>
      <div className="px-[8%] py-10 flex flex-row justify-between gap-12 bg-[#E2D1F9] max-md:hidden">
        <div className="flex flex-col justify-around items-start flex-1">
          <Logo />
          {/* <SubscribeInput /> */}
        </div>
        <div className="flex flex-col gap-8 flex-1">
          <div className="flex flex-row gap-12">
            <Navigation />
            <Policies />
          </div>
          <Socials />
        </div>
        <div className="flex-1">
          <ContactDetails />
        </div>
      </div>

      <div className="hidden max-md:px-[8%] max-md:py-10 max-md:flex max-md:flex-col max-md:justify-between max-md:gap-8 max-md:bg-[#E2D1F9]">
        <div className="flex flex-row gap-8">
          <div className="flex flex-col justify-between flex-1">
            <Logo />
            <Socials />
          </div>
          <div className="flex flex-col gap-8 flex-1">
            <ContactDetails />
            {/* <SubscribeInput /> */}
          </div>
        </div>
        <div className="flex flex-1 max-sm:hidden">
          <Policies />
        </div>
      </div>
    </>
  );
}

export default Footer;
