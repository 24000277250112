import React, { useEffect } from "react";
import AstrologyBanner from "../../assets/AstrologyBanner.webp";
import AstrologyImage from "../../assets/AstrologyImage.webp";
import arrowdownastro from "../../assets/arrowdownastro.svg";
import Footer from "../../components/footer/Footer";
import arrowback from "../../assets/arrowback.svg";
import { Link } from "react-router-dom";

function AstrologyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="bg-[#fff3f2] font-sans"
      style={{
        background: "linear-gradient(to bottom, #FFF1D0 50%, #F4ECFF 100%)",
      }}
    >
      <div className="relative">
        <div className="h-3/4">
          <img
            src={AstrologyBanner}
            alt="Astrology Banner"
            className="w-full h-[30rem] object-none object-center"
          />
        </div>
        <Link
          to="/"
          className="absolute top-10 left-10 max-sm:top-5 max-sm:left-5 flex flex-row items-center gap-2"
        >
          <img
            src={arrowback}
            alt="arrowback"
            className="size-7 max-sm:size-5 cursor-pointer"
          />
          <span className="text-[20px] max-md:text-[16px] text-[#000000]">
            Back to Home
          </span>
        </Link>
        <div className="text-center px-4 py-10 -mt-20 w-full flex flex-col items-center gap-4">
          <h1 className="text-[36px] font-medium w-1/2 max-md:w-full leading-normal font-spectral">
            Unlock Life's Mysteries. Gain deeper insights into your future with
            our all-encompassing Astrology 360-Degree Service
          </h1>
          <p className="text-black text-md sm:text-lg w-full sm:w-1/2 mx-auto mt-4">
            Your destiny, decoded. Your life, reimagined.
          </p>
          <button className="mt-6 px-40 max-md:px-20 py-4 bg-[#fff1d0] text-black text-[20px] font-bold rounded-full border border-black">
            Get Prediction
          </button>
        </div>
      </div>
      <section className="flex flex-row max-md:flex-col justify-center gap-8 py-6 px-4 gap-12 h-full">
        <div className="rounded-[20px]">
          <img
            src={AstrologyImage}
            alt="Astrology Chart"
            className="size-[32rem] rounded-[20px] max-sm:w-full max-sm:h-full"
          />
        </div>
        <div className="flex flex-col justify-around w-full lg:w-1/2">
          <h2 className="text-2xl font-bold text-black-800 mb-6">
            Looking for Answers?
          </h2>
          <p className="text-black-600 mb-6 text-[20px] leading-relaxed">
            Struggling to find answers? We’re here to illuminate your path. Our
            360-Degree Astrology Service combines the wisdom of Vedic Astrology,
            Palmistry, Tarot, Numerology, and more to provide you with
            actionable insights and practical remedies tailored to your unique
            life.
          </p>
          <h2 className="text-2xl font-bold text-black-800 mb-6">
            Feature Benefits
          </h2>
          <ul className="flex flex-wrap justify-between text-black-700 mb-6 text-[20px]">
            <div className="flex flex-col gap-2 w-full md:w-1/2">
              <li>✅ Career & Professional Growth</li>
              <li>✅ Health & Well-being</li>
              <li>✅ Relationship & Love</li>
            </div>
            <div className="flex flex-col gap-2 w-full md:w-1/2">
              <li>✅ Wealth & Financial Stability</li>
              <li>✅ Spiritual Growth</li>
            </div>
          </ul>
          <a
            href="#packages"
            className="text-black-600 font-bold text-[20px] flex flex-row justify-center gap-2 w-full"
          >
            <span>See what the Future Holds...</span>
            <img src={arrowdownastro} alt="arrowdownastro" />
          </a>
        </div>
      </section>

      <section id="packages" className="py-10 px-[8%] bg-[#FFF1D2]">
        <h3 className="text-2xl sm:text-3xl font-bold text-black-800 mb-6">
          Choose Your Package for Maximum Impact
        </h3>
        <p className="text-lg sm:text-2xl font-semibold black-600 mb-12">
          One size doesn’t fit all. Whether you’re single, in a relationship, or
          focused on family, we have tailored packages to meet your unique
          needs.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-16">
          <div
            className="border border-[#F4ECFF] rounded-[20px] shadow-md hover:shadow-lg transition flex flex-col gap-4 justify-between"
            style={{
              background:
                "linear-gradient(0deg, #FFD9C0 0%, #FEE0E8 50%, #FFF1D0 100%)",
            }}
          >
            <div className="p-8">
              <h4 className="text-lg sm:text-xl font-bold text-black-800 mb-4">
                Single Package
              </h4>
              <p className="text-black-600 mb-4">
                <strong>Ideal For:</strong> One individual seeking personal
                guidance.
              </p>
              <ul className="list-disc list-inside text-black-700 space-y-2">
                <h3 className="font-semibold"> Services Covered:</h3>
                <li>
                  Vedic Astrology, Lal Kitab, Palmistry, Tarot, Numerology
                </li>
                <li>
                  Detailed insights into career, health, relationships, wealth,
                  and spiritual growth
                </li>
                <h3 className="font-semibold"> Unique Features:</h3>
                <li>Personalized remedies (mantras, gemstones, rituals)</li>
                <li>Single report covering all major life areas</li>
                <li>Daily/weekly forecasts for ongoing guidance</li>
                <h3 className="font-semibold"> Consultation:</h3>
                <li>
                  One-on-one live session with an astrologer or tarot reader
                </li>
              </ul>
            </div>
            <button className="px-4 py-3 bg-[#ffd3bb] border border-[#FFF1D0] text-black font-semibold rounded-b-[20px]">
              Select package for ₹ 15,000.00
            </button>
          </div>

          <div
            className="border border-[#F4ECFF] rounded-[20px] shadow-md hover:shadow-lg transition flex flex-col gap-4 justify-between"
            style={{
              background:
                "linear-gradient(0deg, #FFD9C0 0%, #FEE0E8 50%, #FFF1D0 100%)",
            }}
          >
            <div className="p-8">
              <h4 className="text-lg sm:text-xl font-bold text-black-800 mb-4">
                Couples Package
              </h4>
              <p className="text-black-600 mb-4">
                <strong>Ideal For:</strong> Couples wanting to explore
                compatibility and shared goals.
              </p>
              <ul className="list-disc list-inside text-black-700 space-y-2">
                <h3 className="font-semibold">Services Covered:</h3>
                <li>
                  Vedic Astrology, Horoscope Matching, Lal Kitab, Palmistry,
                  Tarot, Numerology
                </li>
                <li>
                  Compatibility analysis, relationship insights, shared
                  financial and health forecasts
                </li>
                <h3 className="font-semibold">Unique Features:</h3>
                <li>Joint report covering relationship dynamics and growth</li>
                <li>Tailored remedies for harmony and connection</li>
                <li>Ongoing daily/weekly forecasts for both individuals</li>
                <h3 className="font-semibold">Consultation:</h3>
                <li>
                  Live session focusing on couple compatibility and shared
                  future
                </li>
              </ul>
            </div>
            <button className="px-4 py-3 bg-[#ffd3bb] border border-[#FFF1D0] text-black font-semibold rounded-b-[20px]">
              Select package for ₹ 25,000.00
            </button>
          </div>

          <div
            className="border border-[#F4ECFF] rounded-[20px] shadow-md hover:shadow-lg transition flex flex-col gap-4 justify-between"
            style={{
              background:
                "linear-gradient(0deg, #FFD9C0 0%, #FEE0E8 50%, #FFF1D0 100%)",
            }}
          >
            <div className="p-8">
              <h4 className="text-lg sm:text-xl font-bold text-black-800 mb-4">
                Family Package
              </h4>
              <p className="text-black-600 mb-4">
                <strong>Ideal For:</strong> Families (up to 4 members) seeking a
                holistic view of family well-being..
              </p>
              <ul className="list-disc list-inside text-black-700 space-y-2">
                <h3 className="font-semibold">Services Covered:</h3>
                <li>
                  Vedic Astrology, Lal Kitab, Palmistry, Tarot, Numerology for
                  each family member
                </li>
                <li>
                  Comprehensive insights into career, health, relationships, and
                  finances for each member
                </li>
                <h3 className="font-semibold">Unique Features:</h3>
                <li>Family report with individual and collective insights</li>
                <li>Remedies and rituals for family harmony and growth</li>
                <li>
                  Continuous updates with family-focused daily/weekly forecasts
                </li>
                <h3 className="font-semibold">Consultation:</h3>
                <li>
                  Family consultation session to address collective and
                  individual needs
                </li>
              </ul>
            </div>
            <button className="px-4 py-3 bg-[#ffd3bb] border border-[#FFF1D0] text-black font-semibold rounded-b-[20px]">
              Select package for ₹ 35,000.00
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AstrologyPage;
