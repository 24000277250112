import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./Layout";
import LandingPage from "./pages/landingPage/LandingPage";
import Context from "./context/Context";
import ProductPage from "./pages/productpage/ProductPage";
import UserProfile from "./pages/userprofile/UserProfile";
import GroupPujaPage from "./pages/grouppuja/GroupPujaPage";
import OrderHistory from "./pages/userprofile/sections/orderhistory/OrderHistory";
import PujaOrderDetail from "./pages/userprofile/sections/orderdetail/PujaOrderDetail";
import TemplePujaPage from "./pages/templepuja/TemplePujaPage";
import AstrologyPage from "./pages/astrology/AstrologyPage";
import CartPage from "./pages/cartpage/CartPage";
import MahakumbhPage from "./pages/mahakumbh/mahakumbhmainpage/MahakumbhPage";
import MahaKumbhPackage from "./pages/mahakumbh/mahakumbhpackage/MahaKumbhPackage";
import MahaKumbhBookingPage from "./pages/mahakumbh/mahakumbhbooking/MahaKumbhBookingPage";
import PrivacyPolicyPage from "./pages/policies/PrivacyPolicyPage";
import TermOfServicePage from "./pages/policies/TermOfServicePage";
import RefundPolicyPage from "./pages/policies/RefundPolicyPage";
import { Toaster } from "react-hot-toast";
import TempleOrderDetail from "./pages/userprofile/sections/orderdetail/TempleOrderDetail";
import MahaKumbhOrderDetail from "./pages/userprofile/sections/orderdetail/MahaKumbhOrderDetail";
import StatusPage from "./pages/statuspage/StatusPage";
import AboutPage from "./pages/aboutpage/AboutPage";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: "https://adc1cb91fd73affbf3f66de5eb36170f@o4508054427467776.ingest.us.sentry.io/4508154834714624",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<LandingPage />} />
      <Route path="product" element={<ProductPage />} />
      <Route path="user" element={<UserProfile />} />
      <Route path="group" element={<GroupPujaPage />} />
      <Route path="temple" element={<TemplePujaPage />} />
      <Route path="order" element={<OrderHistory />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="pujaorderdetail" element={<PujaOrderDetail />} />
      <Route path="templeorderdetail" element={<TempleOrderDetail />} />
      <Route path="mahakumbhorderdetail" element={<MahaKumbhOrderDetail />} />
      <Route path="astrology" element={<AstrologyPage />} />
      <Route path="cart" element={<CartPage />} />
      <Route path="kumbh" element={<MahakumbhPage />} />
      <Route path="mahakumbhpackage" element={<MahaKumbhPackage />} />
      <Route path="mahakumbhbooking" element={<MahaKumbhBookingPage />} />
      <Route path="privacypolicy" element={<PrivacyPolicyPage />} />
      <Route path="refundpolicy" element={<RefundPolicyPage />} />
      <Route path="terms&Conditions" element={<TermOfServicePage />} />
      <Route path="thankyoupage/:orderId" element={<StatusPage />} />
    </Route>
  )
);

function App() {
  return (
    <Context>
      <Toaster position="bottom-center" toastOptions={{ duration: 2000 }} />
      <RouterProvider router={router}></RouterProvider>
    </Context>
  );
}

export default App;
