import React from "react";
import happy from "../../../../../assets/grouppujaassets/happy.png";

function GroupPujaBenefits({ pujaBenefits }) {
  return (
    <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20 flex flex-col">
      <h1 className="text-[20px] max-md:text-[18px] font-bold font-inter text-[#00000] mb-10">
        Puja Benefits
      </h1>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-16">
        {pujaBenefits.map((benefit, index) => {
          const { pujaBenefitsTitle, pujaBenefitsSubtitle } = benefit;
          return (
            <div key={index} className="flex flex-row items-start gap-4">
              <img src={happy} alt="happy" className="size-12" />
              <div className="flex flex-col gap-4">
                <h1 className="text-[18px] max-md:text-[16px] font-bold font-inter text-[#00000]">
                  {pujaBenefitsTitle}
                </h1>

                <p className="text-[18px] max-md:text-[16px] text-justify font-normal font-inter text-[#00000]">
                  {pujaBenefitsSubtitle}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GroupPujaBenefits;
