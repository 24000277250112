import React, { useContext } from "react";
import mobileheroimage from "../../../assets/landingpageicons/mobileheroimage.webp";
import Button from "../../../components/button/Button";
import { Link } from "react-router-dom";
import { ContextData } from "../../../context/Context";
import banner1 from "../../../assets/landingpageicons/banner1.webp";

function HeroImage() {
  const { setCurrentPuja } = useContext(ContextData);
  return (
    <div className="relative w-screen h-screen">
      <img
        loading="lazy"
        src={banner1}
        alt="banner1"
        className="w-full h-full object-fit block max-lg:hidden"
      />
      <img
        loading="lazy"
        src={mobileheroimage}
        alt="mobileheroimage"
        className="w-full h-full object-cover hidden max-lg:block"
      />
      <div className="absolute px-[8%] inset-0 flex flex-col justify-center items-start gap-4">
        <div className="flex flex-col gap-2 w-1/3 max-lg:w-full gap-2">
          <h1 className="text-[#000000] text-[22px] font-lora not-italic leading-8 text-center">
            Embrace the Divine with{" "}
            <span className="font-bold">RitiVidhi’s</span> Sacred Rituals!
          </h1>
          <p className="text-[#451f1d] text-[32px] font-quintessential not-italic font-normal text-center">
            Bring Tradition Home. Celebrate with Authenticity and Grace.
          </p>
        </div>
        <div className="w-1/3 max-lg:w-full flex flex-row justify-center items-center gap-4">
          <Link to="/product">
            <Button
              label="Book a Pooja"
              style={{
                backgroundColor: "#FFF1D0",
                color: "black",
                borderColor: "#000000",
                fontFamily: "lora",
              }}
              onClick={() => setCurrentPuja(0)}
            />
          </Link>
          <Link to="/product">
            <Button
              label="Explore"
              style={{
                backgroundColor: "#FFF1D0",
                color: "black",
                borderColor: "#000000",
                fontFamily: "lora",
              }}
              onClick={() => setCurrentPuja(0)}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroImage;
