import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "../../components/button/Button";
import Lottie from "react-lottie";
import successAnimation from "../../../src/assets/lottiefiles/successAnimation.json";
import pendingAnimation from "../../../src/assets/lottiefiles/pendingAnimation.json";
import failedAnimation from "../../../src/assets/lottiefiles/failedAnimation.json";
import { useParams, useNavigate } from "react-router-dom";

function StatusPage() {
  const { orderId } = useParams();
  const [status, setStatus] = useState("pending");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleHomeRedirect = () => {
    navigate("/");
  };

  const handleOrderRedirect = () => {
    navigate("/order");
  };

  const getStatusAnimation = () => {
    switch (status) {
      case "success":
        return successAnimation;
      case "pending":
        return pendingAnimation;
      case "failed":
        return failedAnimation;
      default:
        return pendingAnimation;
    }
  };

  const getStatusMessage = () => {
    switch (status) {
      case "success":
        return "Thank you! Your order has been placed successfully.";
      case "pending":
        return "Your order is currently being processed.";
      case "failed":
        return "Payment Failed! Please try again.";
      default:
        return "Unknown status";
    }
  };

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await axios.get(
          `https://app.ritividhi.com/ritividhi/paymentStatus/${orderId}`
        );
        const paymentStatus = response.data;
        if (paymentStatus.includes("Failed")) {
          setStatus("failed");
        } else if (paymentStatus.includes("Success")) {
          setStatus("success");
        } else {
          setStatus("pending");
        }
      } catch (error) {
        console.error("Error fetching payment status:", error);
        setError("Failed to fetch payment status. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchPaymentStatus();
    }
  }, [orderId]);

  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-col items-center justify-center flex-1">
        {loading && (
          <Lottie
            options={{
              animationData: pendingAnimation,
              loop: true,
              autoplay: true,
            }}
            height={280}
            width={280}
          />
        )}
        {!loading && !error && (
          <Lottie
            options={{
              animationData: getStatusAnimation(),
              loop: status === "pending",
              autoplay: true,
            }}
            height={280}
            width={280}
          />
        )}
        {error && <p>{error}</p>}
        <h2 className="font-inter text-[28px] text-[#000000] text-center font-bold my-4">
          {getStatusMessage()}
        </h2>
        <div className="flex gap-5 my-6">
          <Button
            label="Back to Home"
            style={{
              backgroundColor: "#FBD16E",
              color: "black",
              borderColor: "#FF8213",
            }}
            onClick={() => handleHomeRedirect()}
          />
          {status !== "failed" && (
            <Button
              label="Track My Order"
              style={{
                backgroundColor: "#FBD16E",
                color: "black",
                borderColor: "#FF8213",
              }}
              onClick={() => handleOrderRedirect()}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StatusPage;
