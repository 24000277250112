import React, { createContext, useEffect, useState } from "react";
import officeservice from "../assets/landingpageicons/officeservice.gif";
import ProductInfo from "../pages/productpage/sections/productinfo/ProductInfo";
import AddressForm from "../pages/productpage/sections/AddressForm";
import DateTime from "../pages/productpage/sections/DateTime";
import PujaCategory from "../pages/productpage/sections/category/PujaCategory";
import pujaservices from "../assets/landingpageicons/pujaservices.gif";
import templeservice from "../assets/landingpageicons/templeservice.gif";
import astrology from "../assets/landingpageicons/astrology.gif";
import meditation from "../assets/landingpageicons/meditation.gif";
import grouppujaimage from "../assets/landingpageicons/grouppuja.gif";
import GroupPujaInfo from "../pages/grouppuja/sections/GroupPujaInfo";
import GroupPujaProductpage from "../pages/grouppuja/sections/grouppujaproductpage/GroupPujaProductpage";
import GroupPujaAddress from "../pages/grouppuja/sections/grouppujaaddress/GroupPujaAddress";
import GroupPujaDateTime from "../pages/grouppuja/sections/grouppujadatetime/GroupPujaDateTime";
import TempleCategory from "../pages/templepuja/sections/category/TempleCategory";
import TemplePujas from "../pages/templepuja/sections/templepuja/TemplePujas";
import CurrentTemplePuja from "../pages/templepuja/sections/currenttemplepuja/CurrentTemplePuja";
import TemplePujaAddressForm from "../pages/templepuja/sections/addressdetails/TemplePujaAddressForm";
import DateTimeReview from "../pages/templepuja/sections/datetimeReview/DateTimeReview";
import Cookies from "js-cookie";
import axios from "axios";
import { auth } from "../firebase/setup";

export const ContextData = createContext();

function Context({ children }) {
  const services = [
    {
      id: 1,
      heading: "Puja Services",
      description:
        "Effortlessly book personalized poojas for your home or events, performed by expert pandits with authentic rituals and devotion.",
      imageURL: pujaservices,
      buttonlabel: "Book a Pooja",
      background: "linear-gradient(90deg, #E2D1F9 55.5%, #FFF 100%)",
      redirect: "/product",
      setFunction: () => setCurrentPuja(0),
    },
    {
      id: 2,
      heading: "Temple Services",
      description:
        "Connect with the divine from anywhere. Book poojas at your chosen temple and receive blessings, along with sacred prasad",
      imageURL: templeservice,
      buttonlabel: "Explore",
      background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
      redirect: "/temple",

      setFunction: () => setCurrentTemplePuja(0),
    },
    {
      id: 3,
      heading: "Upcoming Group Pujas on Ritividhi",
      description:
        "Be a part of powerful group pujas. Book in your name and gotra, receive the puja video, tirth prasad, and divine blessings.",
      imageURL: grouppujaimage,
      buttonlabel: "Explore",
      background: "linear-gradient(180deg, #FFEBF7 63%, #FFF 100%)",
      redirect: "/group",
      setFunction: () => setCurrentGroupPuja(0),
    },
    {
      id: 4,
      heading: "Astrology and Consultancy",
      description:
        "Unlock the wisdom of the stars with personalized astrology and expert guidance, helping you navigate life’s important decisions.",
      imageURL: astrology,
      buttonlabel: "Explore",
      background: "linear-gradient(180deg, #FFF 0%, #FBEEB8 51.5%)",
      redirect: "/astrology",
      setFunction: () => {},
    },
    {
      id: 5,
      heading: "Corporate Services (Coming Soon)",
      description:
        "Invite prosperity and success to your business with corporate poojas, including Vastu Shanti, Ganesh Poojan, and other rituals tailored to enhance positive energy in your workplace.",
      imageURL: officeservice,
      buttonlabel: "Book a Pooja",
      background: "linear-gradient(90deg, #F3E9D2 57.5%, #FFF 100%)",
      redirect: "/",
      setFunction: () => {},
    },
    {
      id: 6,
      heading: "Meditation (Coming Soon)",
      description: "Coming Soon...",
      imageURL: meditation,
      buttonlabel: "Book a Pooja",
      background: "linear-gradient(90deg, #F3E9D2 57.5%, #FFF 100%)",
      redirect: "/",
      setFunction: () => {},
    },
  ];

  const allpujasteps = [
    {
      title: "Choose a Puja",
      content: <PujaCategory />,
    },
    {
      title: "Select Package",
      content: <ProductInfo />,
    },
    // {
    //   title: "Delivery Address",
    //   content: <AddressForm />,
    // },
    {
      title: "Date, Time & Review",
      content: <DateTime />,
    },
  ];

  const templepujasteps = [
    {
      title: "Choose a Temple",
      content: <TempleCategory />,
    },
    {
      title: "Choose a Puja",
      content: <TemplePujas />,
    },
    {
      title: "Select Package",
      content: <CurrentTemplePuja />,
    },
    // {
    //   title: "Delivery Address",
    //   content: <TemplePujaAddressForm />,
    // },
    {
      title: "Date, Time & Review",
      content: <DateTimeReview />,
    },
  ];

  const grouppujasteps = [
    {
      title: "Choose a Group Puja",
      content: <GroupPujaInfo />,
    },
    {
      title: "Select Package",
      content: <GroupPujaProductpage />,
    },
    {
      title: "Delivery Address",
      content: <GroupPujaAddress />,
    },
    {
      title: "Date, Time & Review",
      content: <GroupPujaDateTime />,
    },
  ];

  //contact
  const [queryemail, setqueryemail] = useState("");
  const [querymessage, setquerymessage] = useState("");

  //search
  const [searchPujaQuery, setSearchPujaQuery] = useState("");
  const [searchtempleQuery, setSearchTempleQuery] = useState("");

  //users
  const [userid, setuserid] = useState("");

  //category
  const [gods, setGods] = useState([]);
  const [temple, setTemple] = useState([]);

  //Offline Puja
  const [pujasummary, setPujaSummary] = useState([]);
  const [pujasummarycopy, setPujaSummaryCopy] = useState([]);
  const [currentpujadetail, setCurrentpujadetail] = useState([]);
  const [currentpuja, setCurrentPuja] = useState(0);
  const [pujasteps, setPujasteps] = useState(allpujasteps);
  const [selectedmode, setselectedmode] = useState("");
  const [selectedpackage, setselectedpackage] = useState("");
  const [cartpujadata, setCartPujaData] = useState([]);
  const [finalcartpujadata, setFinalCartPujaData] = useState([]);
  const [pujatotal, setPujaTotal] = useState(0);
  const [opencartModal, setOpencartModal] = useState(false);

  //Temple Puja
  const [templesummary, setTempleSummary] = useState([]);
  const [templesummarycopy, setTempleSummaryCopy] = useState([]);
  const [currenttempledetail, setCurrenttempledetail] = useState([]);
  const [selectedtemplepujadetail, setSelectedTemplePujadetail] = useState([]);
  const [currenttemplepuja, setCurrentTemplePuja] = useState(0);
  const [templepujastep, setTemplePujaStep] = useState(templepujasteps);
  const [selectedcopytemplepujadetail, setSelectedCopyTemplePujadetail] =
    useState([]);
  const [selectedtemplepackage, setselectedTemplepackage] = useState("");
  const [selectedtemplemode, setselectedtemplemode] = useState("");
  const [carttemplepujadata, setCarttemplePujaData] = useState([]);
  const [finaltemplecartpujadata, setFinalTempleCartPujaData] = useState([]);
  const [templepujatotal, setTemplePujaTotal] = useState(0);
  const [opentemplepujacartModal, setOpenTemplePujacartModal] = useState(false);

  //Online Puja
  const [grouppujasummary, setGroupPujaSummary] = useState([]);
  const [currentgrouppujadetail, setCurrentgrouppujadetail] = useState([]);
  const [currentgrouptempledetail, setCurrentgrouptempledetail] = useState([]);
  const [currentgrouppuja, setCurrentGroupPuja] = useState(0);
  const [grouppujastep, setGroupPujaStep] = useState(grouppujasteps);
  const [cartgrouppujadata, setCartGroupPujaData] = useState([]);
  const [finalgroupcartpujadata, setFinalGroupCartPujaData] = useState([]);
  const [grouppujatotal, setGroupPujaTotal] = useState(0);
  const [opengrouppujacartModal, setOpenGroupPujacartModal] = useState(false);

  //Service Cards
  const [cards, setcards] = useState(services);

  //Modals
  const [openNavModal, setOpenNavModal] = useState(false);
  const [openloginmodal, setOpenloginmodal] = useState(false);
  const [opendetailnmodal, setOpendetailnmodal] = useState(false);
  const [openpackagenmodal, setOpenpackagemodal] = useState(false);
  const [contactusmodal, setContactUsModal] = useState(false);
  const [packagequerymodal, setPackageQueryModal] = useState(false);

  //MahaKumbh
  const [mahakumbhselectedDate, setMahaKumbhSelectedDate] = useState(null);
  const [mahakumbhselectedEndDate, setMahaKumbhSelectedEndDate] =
    useState(null);
  const [mahakumbhcalendermodal, setMahaKumbhCalendermodal] = useState(false);
  const [mahakumbhroommodal, setMahaKumbhRoomModal] = useState(false);
  const [mahakumbhdata, setMahaKumbhData] = useState([]);
  const [mahakumbhpackagedata, setMahaKumbhPackageData] = useState([]);
  const [mahakumbhorderdata, setMahaKumbhOrderData] = useState([]);
  const [mahakumbhquerynmodal, setMahaKumbhQuerymodal] = useState(false);
  const [mahakumbhadultquantity, setMahaKumbhAdultQuantity] = useState(2);
  const [mahakumbhchildquantity, setMahaKumbhChildQuantity] = useState(0);
  const [mahakumbhroomquantity, setMahaKumbhRoomQuantity] = useState(1);
  const [mahakumbhRoomtotal, setmahakumbhroomtotal] = useState(0);
  const [formValid, setFormValid] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [serviceselectionmodal, setServiceSelectionModal] = useState(false);

  //Package
  const [selectedgrouppackage, setSelectedgrouppackage] = useState([]);

  //Account
  const [loggedIn, setLoggedIn] = useState(false);
  const [login, setLogin] = useState(false);

  //Order
  const [mahakumbhorderlist, setMahaKumbhOrderlist] = useState([]);
  const [pujaorderlist, setPujaOrderlist] = useState([]);
  const [orderlist, setOrderlist] = useState([]);
  const [orderdetail, setorderdetail] = useState([]);
  const [cartSize, setCartSize] = useState(0);

  //Date/Time
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [specialRequirement, setSpecialRequirement] = useState("");

  //FAQ
  const [faqs, setFAQs] = useState("");

  //readmore
  const [pujadescriptionreadmore, setpujadescriptionreadmore] = useState(true);
  const [pujasignificancereadmore, setpujasignificancereadmore] =
    useState(true);
  const [havanreadmore, sethavanreadmore] = useState(true);
  const [pujasreadmore, setpujasreadmore] = useState(true);
  const [yajmanreadmore, setyajmanreadmore] = useState(true);

  //location
  const [latitude, setlatitude] = useState(null);
  const [longitude, setlongitude] = useState(null);
  const [place, setplace] = useState("");
  const [retrigger, setretigger] = useState(true);

  const api_Key = process.env.REACT_APP_MAP_KEY;

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
  //     if (currentUser) {
  //       try {
  //         const freshToken = await currentUser.getIdToken(true);
  //         Cookies.set("userToken", freshToken, {
  //           expires: 7,
  //           secure: true,
  //         });
  //       } catch (error) {
  //         console.error("Error refreshing token:", error);
  //       }
  //     } else {
  //       console.log("Kindly login");
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (auth.currentUser) {
        try {
          const freshToken = await auth.currentUser.getIdToken(true);
          Cookies.set("userToken", freshToken, {
            expires: 7,
            secure: true,
          });
        } catch (error) {
          console.error("Error auto-refreshing token:", error);
        }
      } else {
        console.log("No user logged in. Skipping token refresh.");
      }
    }, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const pujadatafromstorage = localStorage.getItem("pujaData");
      const templepujadatafromstorage = localStorage.getItem("templepujaData");
      const grouppujadatafromstorage = localStorage.getItem("grouppujaData");
      const mahakumbhpackagedatafromstorage =
        localStorage.getItem("mahakumbhpackage");
      const userIdfromstorage = localStorage.getItem("userId");
      const tokenFromCookie = Cookies.get("userToken");

      if (
        pujadatafromstorage ||
        templepujadatafromstorage ||
        grouppujadatafromstorage ||
        tokenFromCookie ||
        userIdfromstorage
      ) {
        try {
          const parsedPujaData = JSON.parse(pujadatafromstorage);
          setFinalCartPujaData(parsedPujaData);

          const parsedTemplePujaData = JSON.parse(templepujadatafromstorage);
          setFinalTempleCartPujaData(parsedTemplePujaData);

          const parsedGroupPujaData = JSON.parse(grouppujadatafromstorage);
          setFinalGroupCartPujaData(parsedGroupPujaData);

          const parsedMahaKumbhData = JSON.parse(
            mahakumbhpackagedatafromstorage
          );
          setMahaKumbhPackageData(parsedMahaKumbhData);
          if (tokenFromCookie) {
            setLoggedIn(true);
          }
          setuserid(userIdfromstorage);
        } catch (error) {
          console.error("Error parsing data from storage:", error);
        }
      } else {
        console.error(
          "No Puja Data or Token found in localStorage or Cookies."
        );
      }
    }
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setlatitude(position.coords.latitude.toFixed(14));
      setlongitude(position.coords.longitude.toFixed(14));

      const fetchPlaces = async () => {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude.toFixed(
              14
            )},${position.coords.longitude.toFixed(14)}&key=${api_Key}`
          );
          const parsedata = await response.json();
          setplace(parsedata?.results[0]?.formatted_address);
        } catch (error) {
          console.error("Error fetching places:", error);
        }
      };
      fetchPlaces();
    });
  }, [latitude, longitude, api_Key, retrigger]);

  useEffect(() => {
    const finalsize =
      (finalcartpujadata && finalcartpujadata.length !== 0 ? 1 : 0) +
      (finaltemplecartpujadata && finaltemplecartpujadata.length !== 0 ? 1 : 0);

    setCartSize(finalsize);
  }, [finalcartpujadata, finaltemplecartpujadata]);

  useEffect(() => {
    const allpuja = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/pujaSummary"
        );
        const pujadata = await response.json();
        setPujaSummary(pujadata);
        setPujaSummaryCopy(pujadata);
      } catch (error) {
        console.error("Error fetching all pujas summary", error);
      }
    };
    allpuja();
  }, []);

  useEffect(() => {
    const allgrouppuja = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/onlineLivePuja"
        );
        const pujadata = await response.json();
        setGroupPujaSummary(pujadata);
      } catch (error) {
        console.error("Error fetching all groupn pujas summary", error);
      }
    };
    allgrouppuja();
  }, []);

  useEffect(() => {
    const templeservice = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/templeSummary"
        );
        const templedata = await response.json();
        setTempleSummary(templedata);
        setTempleSummaryCopy(templedata);
      } catch (error) {
        console.error("Error fetching all temple summary", error);
      }
    };
    templeservice();
  }, []);

  useEffect(() => {
    const FAQdata = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/faqs"
        );
        const faqdata = await response.json();
        setFAQs(
          faqdata.map((faq, index) => ({
            key: String(index + 1),
            label: faq.question,
            children: faq.answer,
          }))
        );
      } catch (error) {
        console.error("Error fetching all FAQ", error);
      }
    };
    FAQdata();
  }, []);

  useEffect(() => {
    const MahaKumbhdata = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/mahakumbh2025"
        );
        const mahakumbhpackagedata = await response.json();
        setMahaKumbhData(mahakumbhpackagedata);
      } catch (error) {
        console.error("Error fetching all mahakumbh data", error);
      }
    };
    MahaKumbhdata();
  }, []);

  useEffect(() => {
    const MahaKumbhorderdata = async () => {
      let token = Cookies.get("userToken");
      try {
        const res = await axios({
          method: "get",
          url: "https://app.ritividhi.com/ritividhi/kumbhOrder/getAllOrdersByUserId",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMahaKumbhOrderlist(res.data);
      } catch (error) {
        console.error("Error occurred during the request", error);
      }
    };
    MahaKumbhorderdata();
  }, [loggedIn]);

  useEffect(() => {
    const pujaorderdata = async () => {
      let token = Cookies.get("userToken");
      try {
        const res = await axios({
          method: "get",
          url: "https://app.ritividhi.com/ritividhi/orders/getAllOrdersByUserId",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPujaOrderlist(res.data);
      } catch (error) {
        console.error("Error occurred during the request", error);
      }
    };
    pujaorderdata();
  }, [loggedIn]);

  const nextstep = () => {
    setCurrentPuja(currentpuja + 1);
  };

  const prevstep = () => {
    setCurrentPuja(currentpuja - 1);
  };

  const nextgroupstep = () => {
    setCurrentGroupPuja(currentgrouppuja + 1);
  };

  const prevgroupstep = () => {
    setCurrentGroupPuja(currentgrouppuja - 1);
  };

  const nexttemplestep = () => {
    setCurrentTemplePuja(currenttemplepuja + 1);
  };

  const prevtemplestep = () => {
    setCurrentTemplePuja(currenttemplepuja - 1);
  };

  const handlecartOpen = () => {
    setOpencartModal(true);
  };

  const handlecartClose = () => {
    setOpencartModal(false);
  };

  const handletemplepujacartOpen = () => {
    setOpenTemplePujacartModal(true);
  };

  const handletemplepujacartClose = () => {
    setOpenTemplePujacartModal(false);
  };

  const handlegrouppujacartOpen = () => {
    setOpenGroupPujacartModal(true);
  };

  const handlegrouppujacartClose = () => {
    setOpenGroupPujacartModal(false);
  };

  const handlepackageOpen = () => {
    setOpenpackagemodal(true);
  };

  const handlepackageClose = () => {
    setOpenpackagemodal(false);
  };

  const handlenavOpen = () => {
    setOpenNavModal(true);
  };

  const handleNavClose = () => {
    setOpenNavModal(false);
  };

  return (
    <ContextData.Provider
      value={{
        userid,
        setuserid,
        pujasummary,
        pujasummarycopy,
        setPujaSummaryCopy,
        gods,
        setGods,
        temple,
        setTemple,
        currentpujadetail,
        setCurrentpujadetail,
        grouppujasummary,
        currentgrouppujadetail,
        setCurrentgrouppujadetail,
        templesummary,
        templesummarycopy,
        setTempleSummaryCopy,
        currenttempledetail,
        setCurrenttempledetail,
        selectedtemplepujadetail,
        setSelectedTemplePujadetail,
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        specialRequirement,
        setSpecialRequirement,
        currentgrouptempledetail,
        setCurrentgrouptempledetail,
        selectedcopytemplepujadetail,
        setSelectedCopyTemplePujadetail,

        cartpujadata,
        setCartPujaData,
        finalcartpujadata,
        setFinalCartPujaData,
        pujatotal,
        setPujaTotal,
        carttemplepujadata,
        setCarttemplePujaData,
        opentemplepujacartModal,
        setOpenTemplePujacartModal,
        handletemplepujacartOpen,
        handletemplepujacartClose,
        finaltemplecartpujadata,
        setFinalTempleCartPujaData,
        templepujatotal,
        setTemplePujaTotal,
        cartgrouppujadata,
        setCartGroupPujaData,
        finalgroupcartpujadata,
        setFinalGroupCartPujaData,
        grouppujatotal,
        setGroupPujaTotal,
        handlegrouppujacartOpen,
        handlegrouppujacartClose,
        opengrouppujacartModal,
        setOpenGroupPujacartModal,
        mahakumbhcalendermodal,
        setMahaKumbhCalendermodal,
        mahakumbhroommodal,
        setMahaKumbhRoomModal,
        mahakumbhselectedDate,
        setMahaKumbhSelectedDate,
        mahakumbhselectedEndDate,
        setMahaKumbhSelectedEndDate,
        mahakumbhdata,
        mahakumbhpackagedata,
        setMahaKumbhPackageData,
        mahakumbhquerynmodal,
        setMahaKumbhQuerymodal,
        mahakumbhadultquantity,
        setMahaKumbhAdultQuantity,
        mahakumbhchildquantity,
        setMahaKumbhChildQuantity,
        mahakumbhroomquantity,
        setMahaKumbhRoomQuantity,
        mahakumbhorderdata,
        setMahaKumbhOrderData,
        mahakumbhRoomtotal,
        setmahakumbhroomtotal,
        formValid,
        setFormValid,
        confirm,
        setConfirm,
        cartSize,
        setCartSize,
        searchPujaQuery,
        setSearchPujaQuery,
        searchtempleQuery,
        setSearchTempleQuery,

        cards,
        setcards,
        currentpuja,
        setCurrentPuja,
        currentgrouppuja,
        setCurrentGroupPuja,
        currenttemplepuja,
        setCurrentTemplePuja,
        nextstep,
        prevstep,
        nextgroupstep,
        prevgroupstep,
        nexttemplestep,
        prevtemplestep,
        pujasteps,
        setPujasteps,
        grouppujastep,
        setGroupPujaStep,
        templepujastep,
        setTemplePujaStep,
        opencartModal,
        setOpencartModal,
        openNavModal,
        setOpenNavModal,
        handlecartOpen,
        handlecartClose,
        handlenavOpen,
        handleNavClose,
        openloginmodal,
        setOpenloginmodal,
        selectedgrouppackage,
        setSelectedgrouppackage,
        selectedmode,
        setselectedmode,
        selectedpackage,
        setselectedpackage,
        selectedtemplepackage,
        setselectedTemplepackage,
        selectedtemplemode,
        setselectedtemplemode,
        loggedIn,
        setLoggedIn,
        login,
        setLogin,
        opendetailnmodal,
        setOpendetailnmodal,
        openpackagenmodal,
        setOpenpackagemodal,
        handlepackageOpen,
        handlepackageClose,
        mahakumbhorderlist,
        setMahaKumbhOrderlist,

        orderlist,
        setOrderlist,
        orderdetail,
        setorderdetail,
        faqs,
        pujaorderlist,

        pujadescriptionreadmore,
        setpujadescriptionreadmore,
        pujasignificancereadmore,
        setpujasignificancereadmore,
        havanreadmore,
        sethavanreadmore,
        pujasreadmore,
        setpujasreadmore,
        yajmanreadmore,
        setyajmanreadmore,

        queryemail,
        setqueryemail,
        querymessage,
        setquerymessage,
        contactusmodal,
        setContactUsModal,
        packagequerymodal,
        setPackageQueryModal,

        serviceselectionmodal,
        setServiceSelectionModal,
        place,
        setplace,
        retrigger,
        setretigger,
      }}
    >
      {children}
    </ContextData.Provider>
  );
}

export default Context;
