import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker, TimePicker, Space, message } from "antd";
import CartModal from "../../../components/modals/CartModal";
import { ContextData } from "../../../context/Context";
import Button from "../../../components/button/Button";
import FooterSmall from "../../../components/footer/FooterSmall";
import moment from "moment";
import Footer from "../../../components/footer/Footer";
import AddOns from "./AddOns";
import toast from "react-hot-toast";

function DateTime() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const {
    pujasteps,
    currentpuja,
    nextstep,
    prevstep,
    setOpencartModal,
    currentpujadetail,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    specialRequirement,
    setSpecialRequirement,
    cartpujadata,
    setCartPujaData,
    place,
    retrigger,
    setretigger,
  } = useContext(ContextData);

  useEffect(() => {
    if (!currentpujadetail || currentpujadetail.length === 0) {
      navigate("/");
    }
  }, [currentpujadetail, navigate]);

  const [addressedit, setaddressedit] = useState(false);
  const [fulladdress, setfulladdress] = useState({
    fullName: "",
    contactNumber: "",
    addressLine1: place,
    addressLine2: "",
    landmark: "",
  });
  const [contactdetail, setcontactdetail] = useState("");

  const [formData, setformData] = useState({
    address1: "",
    address2: place,
  });

  const [contactdetailform, setcontactdetailform] = useState({
    fullName: "",
    contactNumber: "",
  });

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const updatedPuja = () => {
    return cartpujadata.map((currentselectedpuja) => {
      const { homePuja, addOns } = currentselectedpuja;

      let pujaTotal = homePuja.packagePrice || 0;
      addOns.forEach((add) => {
        if (add.quantity > 0) {
          pujaTotal += add.newPrice || 0;
        }
      });
      return { ...currentselectedpuja, totalPrice: pujaTotal };
    });
  };

  const handledate = (date) => {
    if (date) {
      const year = date.$y;
      const month = (date.$M + 1).toString().padStart(2, "0");
      const day = date.$D.toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setSelectedDate(formattedDate);
    } else {
      setSelectedDate(null);
    }
  };

  const handletime = (time) => {
    if (time) {
      const formattedTime = time.format("HH:mm");
      setSelectedTime(formattedTime);
    } else {
      setSelectedTime(null);
    }
  };

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 5; i++) {
      hours.push(i);
    }
    for (let i = 21; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = (hour) => {
    if (hour === 20) {
      return Array.from({ length: 60 }, (_, index) =>
        index > 0 ? index : null
      ).filter(Boolean);
    }
    return [];
  };

  const handleNext = (pujaId) => {
    if (!selectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (!selectedTime) {
      message.error("Please select a time.");
      return;
    }
    if (!contactdetail) {
      message.error("Please add contact details");
      return;
    }
    if (!validatePhone(contactdetailform.contactNumber)) {
      message.error("Please enter a valid 10-digit phone number.");
      return;
    }
    if (!fulladdress) {
      message.error("Please add your Address");
      return;
    }

    const updatedCart = updatedPuja();

    const updatedpujadateTime = updatedCart.map((cartpujadatetime) => {
      if (cartpujadatetime.pujaId === pujaId) {
        return {
          ...cartpujadatetime,
          selectedDate: selectedDate,
          selectedTime: selectedTime,
          specialRequirement: specialRequirement,
          selectedAddress: fulladdress,
          contactDetails: contactdetail,
          fetchedAddress: place,
          status: null,
          pujaImageUrl: cartpujadatetime.pujaImageUrl[0],
          paymentMethod: null,
          paymentStatus: null,
          panditName: null,
          pujaDate: null,
          pujaDetails: null,
          pujaType: null,
        };
      }
      return cartpujadatetime;
    });

    setCartPujaData(updatedpujadateTime);
    nextstep();
  };

  const handleDone = (pujaId) => {
    if (!selectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (!selectedTime) {
      message.error("Please select a time.");
      return;
    }
    if (!contactdetail) {
      message.error("Please add contact details");
      return;
    }
    if (!validatePhone(contactdetailform.contactNumber)) {
      message.error("Please enter a valid 10-digit phone number.");
      return;
    }
    if (!fulladdress) {
      message.error("Please add your Address");
      return;
    }

    const updatedCart = updatedPuja();

    const updatedpujadateTime = updatedCart.map((cartpujadatetime) => {
      if (cartpujadatetime.pujaId === pujaId) {
        return {
          ...cartpujadatetime,
          selectedDate: selectedDate,
          selectedTime: selectedTime,
          specialRequirement: specialRequirement,
          selectedAddress: fulladdress,
          contactDetails: contactdetail,
          fetchedAddress: place,
          status: null,
          pujaImageUrl: cartpujadatetime.pujaImageUrl[0],
          paymentMethod: null,
          paymentStatus: null,
          panditName: null,
          pujaDate: null,
          pujaDetails: null,
          pujaType: null,
        };
      }
      return cartpujadatetime;
    });

    setCartPujaData(updatedpujadateTime);
    setOpencartModal(true);
  };

  const handlecontactchange = (e) => {
    const { name, value } = e.target;
    setcontactdetailform((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleaddressedit = (e) => {
    const { name, value } = e.target;
    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleconfirmdetails = () => {
    setcontactdetail(
      `${contactdetailform.fullName}, ${contactdetailform.contactNumber}`
    );
    toast("Contact details Confirmed", {
      id: "cart-limit-toast",
      icon: "✅",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  const handleaddressconfirm = () => {
    setfulladdress(`${formData.address1}, ${formData.address2}`);
    toast("Address Updated", {
      id: "cart-limit-toast",
      icon: "✅",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
    setaddressedit(!addressedit);
  };

  const handlefetchlocation = () => {
    setretigger(!retrigger);
    setaddressedit(!addressedit);
  };

  return (
    <>
      <div className="w-full">
        {currentpujadetail.map((currentselectedpuja) => {
          const { pujaId, name, pujaImageURL } = currentselectedpuja;

          const pujaImage =
            pujaImageURL && pujaImageURL[0]
              ? pujaImageURL[0].toLowerCase()
              : null;
          let newimageURL;

          if (pujaImage) {
            const pujaimagearray = pujaImage.split("/");
            newimageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
          } else {
            newimageURL = "";
          }
          return (
            <div
              key={pujaId}
              className="flex flex-row max-md:gap-0 max-md:flex-col"
            >
              <div className="flex-1">
                <img
                  src={newimageURL}
                  alt={name}
                  className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                />
              </div>
              <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                <div className="mt-5 px-10">
                  <Button
                    label="Previous"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      borderColor: "#FF8213",
                    }}
                    onClick={() => prevstep()}
                  />
                </div>
                <div className="mt-5 px-10">
                  <span className="font-inter text-[#000000] text-[32px] max-md:text-[28px] font-bold w-1/2">
                    {name}
                  </span>
                </div>
                <hr className="bg-[#795EE3] h-1 w-full my-4" />

                <div className="flex flex-col text-[20px] max-md:text-[16px] text-[#000000] font-inter font-bold text-justify gap-4 px-10">
                  <span>Contact Details and Address</span>
                </div>

                <div className="flex flex-col gap-2 my-5 px-10">
                  <div className="flex flex-row max-sm:flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <label className=" font-semibold">
                        Full Name <span className="text-[#db3131]">*</span>
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        value={contactdetail.fullName}
                        onChange={handlecontactchange}
                        className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
                      />
                    </div>

                    <div className="flex flex-col gap-2">
                      <label className=" font-semibold">
                        Contact Number <span className="text-[#db3131]">*</span>
                      </label>
                      <input
                        type="text"
                        name="contactNumber"
                        value={contactdetail.contactNumber}
                        onChange={handlecontactchange}
                        className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
                      />
                    </div>
                  </div>

                  <div className="h-fit">
                    <Button
                      label="Confirm"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleconfirmdetails}
                    />
                  </div>
                </div>

                <div className="text-[18px] max-md:text-[14px] text-[#000000] font-inter font-bold text-justify gap-4 px-10">
                  <span>Address</span>
                </div>

                {addressedit && place.length !== 0 ? (
                  <div className="flex flex-col gap-2 my-5 px-10">
                    <label>
                      House/ Flat/ Floor Number/ Apartment{" "}
                      <span className="text-[#db3131]">*</span>
                    </label>
                    <input
                      type="text"
                      name="address1"
                      className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
                      value={formData.address1}
                      onChange={handleaddressedit}
                    />

                    <label>
                      Full Address <span className="text-[#db3131]">*</span>
                    </label>
                    <input
                      type="text"
                      name="address2"
                      className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
                      value={formData.address2}
                      onChange={handleaddressedit}
                    />

                    <div className="my-2 text-[#db3131] text-[18px] max-md:text-[14px]">
                      {fulladdress.addressLine1 ? (
                        <span>{fulladdress.addressLine1}</span>
                      ) : (
                        <span>{place}</span>
                      )}
                    </div>
                    <Button
                      label="Confirm"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleaddressconfirm}
                    />
                  </div>
                ) : (
                  <div className="flex flex-row items-center gap-2 px-10 mb-5 mt-2 text-[#000000] text-[18px] max-md:text-[14px]">
                    {place ? (
                      <span>
                        {fulladdress.addressLine1}{" "}
                        <span
                          onClick={handlefetchlocation}
                          className="cursor-pointer"
                        >
                          ✏️
                        </span>
                      </span>
                    ) : (
                      <div className="flex flex-col gap-2 w-full">
                        <label>
                          House/ Flat/ Floor Number/ Apartment{" "}
                          <span className="text-[#db3131]">*</span>
                        </label>
                        <input
                          type="text"
                          name="address1"
                          className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
                          value={formData.address1}
                          onChange={handleaddressedit}
                        />

                        <label>
                          Full Address <span className="text-[#db3131]">*</span>
                        </label>
                        <input
                          type="text"
                          name="address2"
                          className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
                          value={formData.address2}
                          onChange={handleaddressedit}
                        />

                        <div className="my-2 text-[#000000] text-[18px] max-md:text-[14px]">
                          {fulladdress.addressLine1 ? (
                            <span>
                              Selected Address :{" "}
                              <span className="text-[#db3131]">
                                {fulladdress.addressLine1}
                              </span>
                            </span>
                          ) : (
                            <span>
                              {" "}
                              Selected Address :{" "}
                              <span className="stext-[#db3131]">{place}</span>
                            </span>
                          )}
                        </div>
                        <Button
                          label="Confirm"
                          style={{
                            backgroundColor: "#FBD16E",
                            color: "black",
                            borderColor: "#FF8213",
                          }}
                          onClick={handleaddressconfirm}
                        />
                      </div>
                    )}
                  </div>
                )}

                <div className="flex flex-col text-[20px] max-md:text-[16px] text-[#000000] font-inter font-bold text-justify gap-4 px-10">
                  <span>Date and Time Preferences</span>
                  <span className="font-medium text-[16px] max-md:text-[12px]">
                    Please let us know your preferable date and time?
                  </span>
                </div>

                <div className="flex flex-row gap-8 items-center px-10">
                  <Space direction="vertical" className="my-10">
                    <DatePicker
                      onChange={(date) => handledate(date)}
                      size="large"
                      disabledDate={(current) =>
                        current &&
                        (current < moment().endOf("day") ||
                          current > moment().add(1, "year").endOf("day"))
                      }
                    />
                  </Space>

                  <Space wrap>
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      onChange={(time) => handletime(time)}
                      size="large"
                      disabledHours={disabledHours}
                      disabledMinutes={disabledMinutes}
                      minuteStep={1}
                    />
                  </Space>
                </div>

                <div className="flex flex-col text-[20px] max-md:text-[16px] font-inter font-bold text-justify font-medium gap-4 px-10">
                  <span>Any special Requirement? (Optional)</span>
                  <span className="font-medium text-[16px] max-md:text-[12px]">
                    If you are having any specific or special requirement please
                    feel free to let us know
                  </span>
                  <textarea
                    className="rounded-[10px] bg-[#FFDBBB] p-2"
                    value={specialRequirement}
                    onChange={(e) => setSpecialRequirement(e.target.value)}
                  ></textarea>
                </div>

                <AddOns />

                <div className="flex flex-row justify-around items-center px-10">
                  {currentpuja > 0 && (
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => prevstep()}
                    />
                  )}
                  {currentpuja < pujasteps.length - 1 && (
                    <Button
                      label="Next"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => handleNext(pujaId)}
                    />
                  )}

                  {currentpuja === pujasteps.length - 1 && (
                    <Button
                      label="Review"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => handleDone(pujaId)}
                    />
                  )}
                </div>
                <div className="block max-sm:hidden">
                  <FooterSmall />
                </div>
                <div className="hidden max-sm:block">
                  <Footer />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <CartModal />
    </>
  );
}

export default DateTime;
