import React from "react";

function InclusionExclusion({ mahakumbhpackagedata }) {
  return (
    <>
      {mahakumbhpackagedata && mahakumbhpackagedata.length !== 0 ? (
        <div className="px-[8%] pb-10 pt-16 flex flex-col gap-4">
          <h1 className="font-sans text-[24px] max-sm:text-[18px] text-[#850101] font-bold">
            Inclusion/Exclusion
          </h1>
          <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-4">
            <div className="bg-[#F9E0E0] border-2 border-[#850101] p-4 rounded-[20px]">
              <h1 className="text-[16px] max-sm:text-[14px] font-bold text-[#850101] font-inter mb-2">
                Accommodation
              </h1>
              <ul className="list-disc px-4">
                {mahakumbhpackagedata.incl_exlu.accommodation.map(
                  (accommodation, index) => (
                    <li
                      className="text-[14px] max-sm:text-[12px] font-normal text-[#850101] font-inter"
                      key={index}
                    >
                      {accommodation}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="bg-[#F9E0E0] border-2 border-[#850101] p-4 rounded-[20px]">
              <h1 className="text-[16px] max-sm:text-[14px] font-bold text-[#850101] font-inter mb-2">
                Activities
              </h1>
              <ul className="list-disc px-4">
                {mahakumbhpackagedata.incl_exlu.activities.map(
                  (activities, index) => (
                    <li
                      className="text-[14px] max-sm:text-[12px] font-normal text-[#850101] font-inter"
                      key={index}
                    >
                      {activities}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="bg-[#F9E0E0] border-2 border-[#850101] p-4 rounded-[20px]">
              <h1 className="text-[16px] max-sm:text-[14px] font-bold text-[#850101] font-inter mb-2">
                Amenities
              </h1>
              <ul className="list-disc px-4">
                {mahakumbhpackagedata.incl_exlu.amenities.map(
                  (amenities, index) => (
                    <li
                      className="text-[14px] max-sm:text-[12px] font-normal text-[#850101] font-inter"
                      key={index}
                    >
                      {amenities}
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className="bg-[#F9E0E0] border-2 border-[#850101] p-4 rounded-[20px]">
              <h1 className="text-[16px] max-sm:text-[14px] font-bold text-[#850101] font-inter mb-2">
                Exclusions
              </h1>
              <ul className="list-disc px-4">
                {mahakumbhpackagedata.incl_exlu.exclusions.map(
                  (exclusions, index) => (
                    <li
                      className="text-[14px] max-sm:text-[12px] font-normal text-[#850101] font-inter"
                      key={index}
                    >
                      {exclusions}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="bg-[#F9E0E0] border-2 border-[#850101] p-4 rounded-[20px]">
              <h1 className="text-[16px] max-sm:text-[14px] font-bold text-[#850101] font-inter mb-2">
                Meals
              </h1>
              <ul className="list-disc px-4">
                {mahakumbhpackagedata.incl_exlu.meals.map((meals, index) => (
                  <li
                    className="text-[14px] max-sm:text-[12px] font-normal text-[#850101] font-inter"
                    key={index}
                  >
                    {" "}
                    {meals}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-[#F9E0E0] border-2 border-[#850101] p-4 rounded-[20px]">
              <h1 className="text-[16px] max-sm:text-[14px] font-bold text-[#850101] font-inter mb-2">
                Transportation
              </h1>
              <ul className="list-disc px-4">
                {mahakumbhpackagedata.incl_exlu.transportation.map(
                  (transportation, index) => (
                    <li
                      className="text-[14px] max-sm:text-[12px] font-normal text-[#850101] font-inter"
                      key={index}
                    >
                      {transportation}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default InclusionExclusion;
