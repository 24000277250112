import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ContextData } from "../../../context/Context";

function NavigationItems() {
  const { setCurrentPuja, setCurrentTemplePuja, setCurrentGroupPuja } =
    useContext(ContextData);
  const location = useLocation();

  const activeClass = "border-b-2 border-orange-500 ";

  return (
    <div className="flex flex-row gap-4 items-center text-[#595959] font-sans text-[16px] font-semibold leading-6 not-italic">
      <NavLink
        to="/"
        className={({ isActive }) => (isActive ? activeClass : "")}
      >
        <button>Home</button>
      </NavLink>
      <NavLink
        to="/product"
        className={({ isActive }) => (isActive ? activeClass : "")}
        onClick={() => setCurrentPuja(0)}
      >
        <button>Book a Pooja</button>
      </NavLink>
      <NavLink
        to="/kumbh"
        className={({ isActive }) => (isActive ? activeClass : "")}
      >
        <button className="text-transparent bg-gradient-to-r from-[#ff9800] via-[#ff5757] to-[#ff9800] bg-[length:400%_400%] animate-gradient bg-clip-text">
          Maha Kumbh
        </button>
      </NavLink>
      <NavLink
        to="/temple"
        className={({ isActive }) => (isActive ? activeClass : "")}
        onClick={() => setCurrentTemplePuja(0)}
      >
        <button>Temple Services</button>
      </NavLink>
      <NavLink
        to="/group"
        className={({ isActive }) => (isActive ? activeClass : "")}
        onClick={() => setCurrentGroupPuja(0)}
      >
        <button>Group Puja</button>
      </NavLink>
      {location.pathname === "/" && (
        <a href="#about">
          <button>About us</button>
        </a>
      )}
      <a
        href="https://blog.ritividhi.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button>Read</button>
      </a>
    </div>
  );
}

export default NavigationItems;
