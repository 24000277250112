import React, { useContext, useState } from "react";
import { ContextData } from "../../context/Context";
import { Modal } from "@mui/material";
import cross from "../../assets/cross.svg";
import axios from "axios";
import toast from "react-hot-toast";

function PackageQueryModal({ name }) {
  const { packagequerymodal, setPackageQueryModal } = useContext(ContextData);

  const handleClose = () => {
    setPujaName(name);
    setPackageQueryModal(false);
  };

  const { queryemail, setqueryemail } = useContext(ContextData);

  const [pujaname, setPujaName] = useState(name);
  const [pujatype, setPujaType] = useState("");
  const [fullName, setFullName] = useState("");
  const [querymessage, setquerymessage] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");

  const handleTitleChange = (e) => {
    setPujaName(e.target.innerText);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setqueryemail(email);

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handlePujaTypeChange = (e) => {
    setPujaType(e.target.value);
  };

  const handlequerymessage = (e) => {
    setquerymessage(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setPhone(phone);

    if (!validatePhone(phone)) {
      setPhoneError("Phone number must be 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  const handlemessage = async () => {
    let valid = true;

    if (fullName.trim() === "") {
      setNameError("Full name is required.");
      valid = false;
    } else {
      setNameError("");
    }

    if (!validateEmail(queryemail)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    }

    if (!validatePhone(phone)) {
      setPhoneError("Phone number must be 10 digits.");
      valid = false;
    }

    if (!valid) return;

    const data = {
      fullName: fullName,
      phone: phone,
      email: queryemail,
      pujaName: pujaname,
      pujaType: pujatype,
      comments: querymessage,
    };

    try {
      await axios({
        method: "post",
        url: "https://app.ritividhi.com/ritividhi/contactUs",
        data: data,
      });
      setFullName("");
      setPhone("");
      setPujaName(name);
      setPujaType("");
      setqueryemail("");
      setquerymessage("");
      setPackageQueryModal(false);
      toast("Our Team will contact you soon", {
        icon: "✅",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (error) {
      console.error("Error occurred during the request", error);
    }
  };

  return (
    <Modal
      open={packagequerymodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      className="bg-[#0000001A]"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/4 max-md:w-full bg-[#FCF0E0] border-2 border-[#FF8213CC] shadow-xl px-8 py-5 rounded-[10px]">
        <div
          id="modal-modal-description"
          style={{ marginTop: "8px" }}
          className="max-sm:gap-4 w-full p-4 max-sm:p-0"
        >
          <div className="flex flex-col gap-2">
            <div className="w-full flex flex-row justify-between items-center gap-2">
              <div className="flex flex-col gap-2 w-full">
                <span className="font-inter text-[16px] max-md:text-[12px] text-[#000000] font-bold">
                  Puja Name
                </span>
                <h1
                  className="text-[#ff8213] text-[26px] max-md:text-[22px] font-sans font-bold w-full px-2 py-1"
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={handleTitleChange}
                >
                  {pujaname}
                </h1>
              </div>
              <img
                src={cross}
                alt="cross"
                className="size-8 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Puja Type{" "}
                    <span className="text-[16px] max-md:text-[14px] text-[#FF8213]">
                      (Anushthan/Jaap)
                    </span>
                  </span>
                  <div className="flex flex-row gap-4 border border-[#000000] py-1 px-2 rounded-[10px] focus:outline-none">
                    <input
                      type="text"
                      value={pujatype}
                      onChange={handlePujaTypeChange}
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[12px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                      placeholder="Puja Type"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                  Query Message
                </span>
                <div className="flex flex-row gap-4 border border-[#000000] py-1 px-2 rounded-[10px] focus:outline-none">
                  <input
                    type="text"
                    value={querymessage}
                    onChange={handlequerymessage}
                    className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[12px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                    placeholder="Your message"
                  />
                </div>
              </div>
              {nameError && (
                <p className="text-red-500 text-sm mt-1">{nameError}</p>
              )}
              <hr className="h-[1px] w-full bg-[#A9A9A9]" />
              <div className="flex flex-row max-md:flex-col gap-4 max-md:gap-2">
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Full Name
                  </span>
                  <div className="flex flex-row gap-4 border border-[#000000] py-1 px-2 rounded-[10px] focus:outline-none">
                    <input
                      type="text"
                      value={fullName}
                      onChange={handleFullNameChange}
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[12px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                      placeholder="Full name"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Email
                  </span>
                  <div className="flex flex-row gap-4 border border-[#000000] py-1 px-2 rounded-[10px] focus:outline-none">
                    <input
                      type="text"
                      value={queryemail}
                      onChange={handleEmailChange}
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[12px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                      placeholder="Email"
                    />
                  </div>
                  {emailError && (
                    <p className="text-red-500 text-sm mt-1">{emailError}</p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Phone Number
                  </span>
                  <div className="flex flex-row gap-4 border border-[#000000] py-1 px-2 rounded-[10px] focus:outline-none">
                    <input
                      type="text"
                      value={phone}
                      onChange={handlePhoneChange}
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[12px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                      placeholder="Phone Number"
                    />
                  </div>
                  {phoneError && (
                    <p className="text-red-500 text-sm mt-1">{phoneError}</p>
                  )}
                </div>
              </div>
              <div className="w-full flex justify-center">
                <button
                  className="w-fit bg-[#850101] px-12 max-sm:px-6 py-3 text-[#ffffff] rounded-[20px] text-[16px] mb-2"
                  onClick={handlemessage}
                >
                  Submit your Query
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PackageQueryModal;
