import React, { useContext, useState } from "react";
import { ContextData } from "../../context/Context";
import { Modal } from "@mui/material";
import cross from "../../assets/cross.svg";
import axios from "axios";
import toast from "react-hot-toast";

function ContactUsModal() {
  const { contactusmodal, setContactUsModal } = useContext(ContextData);

  const handleClose = () => {
    setFullName("");
    setPhone("");
    setPujaName("");
    setPujaType("");
    setservicetype("");
    setqueryemail("");
    setquerymessage("");
    setDate("");
    setContactUsModal(false);
  };

  const { queryemail, setqueryemail } = useContext(ContextData);

  const [pujaname, setPujaName] = useState("");
  const [pujatype, setPujaType] = useState("");
  const [fullName, setFullName] = useState("");
  const [querymessage, setquerymessage] = useState("");
  const [servicetype, setservicetype] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const [pujaNameError, setPujaNameError] = useState("");
  const [pujaTypeError, setPujaTypeError] = useState("");
  const [dateError, setDateError] = useState("");

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setqueryemail(email);
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handlePujaNameChange = (e) => {
    setPujaName(e.target.value);
  };

  const handlePujaTypeChange = (e) => {
    setPujaType(e.target.value);
  };

  const handleServiceTypeChange = (e) => {
    setservicetype(e.target.value);
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    if (date) {
      console.log(date);
      setDate(date);
    } else {
      setDate("");
    }
  };

  const handlequerymessage = (e) => {
    setquerymessage(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setPhone(phone);

    if (!validatePhone(phone)) {
      setPhoneError("Phone number must be 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  const handlemessage = async () => {
    let valid = true;

    if (fullName.trim() === "") {
      setNameError("Full name is required.");
      valid = false;
    } else {
      setNameError("");
    }

    if (pujaname.trim() === "") {
      setPujaNameError("Puja name is required.");
      valid = false;
    } else {
      setPujaNameError("");
    }

    if (pujatype.trim() === "") {
      setPujaTypeError("Puja type is required.");
      valid = false;
    } else {
      setPujaTypeError("");
    }

    if (date.trim() === "") {
      setDateError("Date is required.");
      valid = false;
    } else {
      setDateError("");
    }

    if (!validatePhone(phone)) {
      setPhoneError("Phone number must be 10 digits.");
      valid = false;
    }

    if (!valid) return;

    const data = {
      fullName: fullName,
      phone: phone,
      email: queryemail,
      pujaName: pujaname,
      pujaType: pujatype,
      comments: querymessage,
      serviceType: servicetype,
      date: date,
    };

    try {
      await axios({
        method: "post",
        url: "https://app.ritividhi.com/ritividhi/contactUs",
        data: data,
      });

      setFullName("");
      setPhone("");
      setPujaName("");
      setPujaType("");
      setservicetype("");
      setqueryemail("");
      setquerymessage("");
      setDate("");
      setContactUsModal(false);
      toast("Our Team will contact you soon", {
        icon: "✅",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (error) {
      console.error("Error occurred during the request", error);
    }
  };

  return (
    <Modal
      open={contactusmodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      className="bg-[#0000001A]"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/4 max-lg:w-2/3 max-md:w-full bg-[#FCF0E0] border-2 border-[#FF8213CC] shadow-xl px-8 py-5 rounded-[10px]">
        <div
          id="modal-modal-description"
          style={{ marginTop: "8px" }}
          className="max-sm:gap-4 w-full p-4 max-sm:p-0"
        >
          <div className="flex flex-col gap-2">
            <div className="w-full flex flex-row justify-between gap-2">
              <h1 className="text-[#ff8213] text-[26px] max-md:text-[22px] font-sans font-bold">
                Embark on a Spiritual Journey with Us
              </h1>
              <img
                src={cross}
                alt="cross"
                className="size-8 cursor-pointer mt-5"
                onClick={handleClose}
              />
            </div>

            <hr className="h-[1px] w-full bg-[#A9A9A9]" />

            <div className="flex flex-col gap-4">
              <div className="flex flex-row max-md:flex-col gap-2">
                <div className="flex flex-col justify-between gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Puja Name
                  </span>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
                      <input
                        type="text"
                        value={pujaname}
                        onChange={handlePujaNameChange}
                        className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1 focus:outline-none"
                        placeholder="Puja Name"
                      />
                    </div>
                    <span>
                      {pujaNameError && (
                        <p className="text-red-500 text-sm mt-1">
                          {pujaNameError}
                        </p>
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col justify-between gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Puja Type{" "}
                    <span className="text-[16px] max-md:text-[14px] text-[#FF8213]">
                      (Anusthan, Jaap, Strotra Path, Dosh Puja)
                    </span>
                  </span>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
                      <input
                        type="text"
                        value={pujatype}
                        onChange={handlePujaTypeChange}
                        className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1 focus:outline-none"
                        placeholder="Puja Type"
                      />
                    </div>
                    <span>
                      {pujaTypeError && (
                        <p className="text-red-500 text-sm mt-1">
                          {pujaTypeError}
                        </p>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-row max-md:flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <label htmlFor="services" className="font-bold text-[20px]">
                    Service Type
                  </label>
                  <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
                    <select
                      name="services"
                      id="services"
                      value={servicetype}
                      onChange={handleServiceTypeChange}
                      className="w-full appearance-none bg-transparent focus:outline-none"
                    >
                      <option value="" disabled>
                        Select your type
                      </option>
                      <option value="puja-services">Puja Services</option>
                      <option value="temple-service">Temple Services</option>
                      <option value="group-puja-service">
                        Group Puja Services
                      </option>
                      <option value="astrology">
                        Astrology and Consultancy
                      </option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="font-bold text-[20px]">Date</span>
                  <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
                    <input
                      type="date"
                      value={date}
                      onChange={handleDateChange}
                      className="appearance-none bg-transparent focus:outline-none"
                    />
                  </div>
                  {dateError && (
                    <p className="text-red-500 text-sm mt-1">{dateError}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                  Query Message
                </span>
                <div className="flex flex-row gap-2 border border-[#000000] p-2 rounded-[10px]">
                  <textarea
                    type="text"
                    value={querymessage}
                    onChange={handlequerymessage}
                    className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-2 focus:outline-none"
                    placeholder="Optional"
                  />
                </div>
              </div>
              <hr className="h-[1px] w-full bg-[#A9A9A9]" />
              <div className="flex flex-row max-md:flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Full Name
                  </span>
                  <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
                    <input
                      type="text"
                      value={fullName}
                      onChange={handleFullNameChange}
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1 focus:outline-none"
                      placeholder="Full name"
                    />
                  </div>
                  {nameError && (
                    <p className="text-red-500 text-sm mt-1">{nameError}</p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Email
                  </span>
                  <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
                    <input
                      type="text"
                      value={queryemail}
                      onChange={handleEmailChange}
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1 focus:outline-none"
                      placeholder="Optional"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-bold">
                    Phone Number
                  </span>
                  <div className="flex flex-row gap-4 border border-[#000000] p-2 rounded-[10px]">
                    <input
                      type="text"
                      value={phone}
                      onChange={handlePhoneChange}
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1 focus:outline-none"
                      placeholder="Phone Number"
                    />
                  </div>
                  {phoneError && (
                    <p className="text-red-500 text-sm mt-1">{phoneError}</p>
                  )}
                </div>
              </div>
              <div className="w-full flex justify-center">
                <button
                  className="w-fit bg-[#850101] px-12 max-sm:px-6 py-3 text-[#ffffff] rounded-[20px] text-[16px] mb-2"
                  onClick={handlemessage}
                >
                  Submit your Query
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ContactUsModal;
