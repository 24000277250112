import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import arrowback from "../../assets/arrowback.svg";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";

function RefundPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <>
      <NavBar />
      <div className="py-10 flex flex-col min-h-screen p-6 px-[10%]">
        <div className="flex flex-row items-center mb-10">
          <img
            src={arrowback}
            alt="arrowback"
            onClick={handleBackClick}
            className="mr-10 size-6 cursor-pointer"
          />
          <div className="font-quicksand text-[#000000] text-[25px] max-sm:text-[20px] font-bold">
            Refund Policy
          </div>
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-2xl shadow-lg">
          <p className="mb-4">Last updated August 10, 2024</p>

          <p className="mb-4">
            Thank you for your purchase. We hope you are happy with your
            purchase. However, if you are not completely satisfied, you may
            return the item to us for a full refund. Please see below for more
            details on our return policy.
          </p>

          <h2 className="text-xl font-semibold mb-2">Refunds</h2>
          <p className="mb-4">
            All refund requests must be postmarked within fourteen (14) days of
            the purchase date.
          </p>

          <h2 className="text-xl font-semibold mb-2">Refund Process</h2>
          <p className="mb-4">
            To initiate a refund, please include your proof of purchase and mail
            your return to the following address:
          </p>
          <address className="mb-4">
            Aiviktek Consulting Private Limited
            <br />
            Attn: Returns
            <br />
            A258, Chhatarpur Enclave Phase 2,
            <br />
            Delhi, 110074
            <br />
            India
          </address>
          <p className="mb-4">
            Return shipping charges will be paid or reimbursed by us.
          </p>

          <p className="mb-4">
            After receiving your return and inspecting the condition of your
            item, we will process your refund. Please allow at least fourteen
            (14) days from the receipt of your item to process your return.
            Refunds may take 1-2 billing cycles to appear on your credit card
            statement, depending on your credit card company. We will notify you
            by email once your return has been processed.
          </p>

          <h2 className="text-xl font-semibold mb-2">Exceptions</h2>
          <p className="mb-4">
            For defective or damaged products, please contact us at the email
            below to arrange a refund or exchange.
          </p>

          <h2 className="text-xl font-semibold mb-2">Questions</h2>
          <p>
            If you have any questions regarding our return policy, please
            contact us at:
          </p>
          <p className="mt-2">
            Email:{" "}
            <a
              href="mailto:contact@ritividhi.com"
              className="text-blue-600 underline"
            >
              contact@ritividhi.com
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RefundPolicyPage;
