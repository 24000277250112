import React from "react";
import LazyLoad from "react-lazyload";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import astro from "../../assets/landingpageicons/astro.gif";

function Service4() {
  return (
    <div
      className="flex flex-col justify-between gap-4 border border-2 border-[#FCDC5B] rounded-[20px] p-4 max-xl:p-10 h-full"
      style={{ background: "linear-gradient(180deg, #FFF 0%, #FBEEB8 51.5%)" }}
    >
      <div>
        <h1 className="text-[28px] font-sans non-italic font-bold text-[#000000]">
          Astrology and Consultancy
        </h1>
        <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4">
          Unlock the wisdom of the stars with personalized astrology and expert
          guidance, helping you navigate life’s important decisions.
        </p>
        <Link to="/astrology">
          <Button
            label="Explore"
            style={{
              backgroundColor: "#FFF1D0",
              color: "black",
              borderColor: "#000000",
            }}
          />
        </Link>
      </div>
      <LazyLoad height={300}>
        <img src={astro} alt="astro" className="w-auto h-auto" />
      </LazyLoad>
    </div>
  );
}

export default Service4;
