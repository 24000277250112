import React from "react";

function MahaKumbhSummaryCard({ order }) {
  let updateddate = order.selectedStartDate.split("T");
  let updatedtime = order.selectedEndDate.split("T");

  return (
    <div className="border border-[#D9D9D9] rounded-[10px] px-8 py-4 flex flex-col gap-8 bg-[#fff1cf]">
      <div className="flex flex-row items-center justify-between">
        <span className="text-[20px] max-md:text-[16px] font-inter text-[#000000] font-bold">
          MahaKumbh Summary
        </span>
        <div className="flex flex-col gap-4">
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Order Status
          </span>
          {order.status === "SUCCESS" ? (
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#067841] font-semibold">
              Preparing for Puja
            </span>
          ) : (
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#ff4d4f] font-semibold">
              Payment Pending
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row max-md:flex-col gap-8">
        <div>
          <img
            src=""
            alt={order.mahaKumbhPackageDetails.packageName}
            className="w-[250px] h-[200px] rounded-[10px] flex flex-1"
            style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          />
        </div>
        <div className="flex flex-col gap-4 flex-1">
          <span className="font-inter text-[20px] max-md:text-[16px] font-bold text-[#643EF9]">
            {order.mahaKumbhPackageDetails.packageName}
          </span>
          <div className="flex flex-row items-start gap-8">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  Start Date :
                </span>
                <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                  {updateddate[0]}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                  End Date :
                </span>
                <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                  {updatedtime[0]}
                </span>
              </div>
              <div>
                <div className="flex flex-row gap-2 items-center">
                  <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                    Total Number Of Devotees :
                  </span>
                  <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                    {order.totalNumberOfDevotees}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#000000]">
                Total No Of Days :
              </span>
              <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
                {order.totalNoOfDays}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MahaKumbhSummaryCard;
