import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";
import PackageDetailModal from "../../../../../components/modals/PackageDetailModal";
import info from "../../../../../assets/info.svg";
import PackageQueryModal from "../../../../../components/modals/PackageQueryModal";

function TemplePujaPackageSelection({ pujaId, allpackages, name }) {
  const {
    selectedtemplemode,
    carttemplepujadata,
    setCarttemplePujaData,
    selectedcopytemplepujadetail,
    setSelectedCopyTemplePujadetail,
    setOpenpackagemodal,
    selectedpackage,
    setselectedpackage,
    setPackageQueryModal,
  } = useContext(ContextData);

  const handleselectedpackage = (singlepackage) => {
    if (carttemplepujadata.length > 0) {
      const updatedCartPujaData = [...carttemplepujadata];
      updatedCartPujaData[0] = {
        ...updatedCartPujaData[0],
        pujas: updatedCartPujaData[0].pujas.map((puja) => ({
          ...puja,
          homePuja: singlepackage,
        })),
      };
      const updatedpackage = selectedcopytemplepujadetail.map(
        (selectedpackagedetail) => ({
          ...selectedpackagedetail,
          homePuja: updatedCartPujaData[0].pujas[0].homePuja,
        })
      );

      setselectedpackage(singlepackage);
      setSelectedCopyTemplePujadetail(updatedpackage);
      setCarttemplePujaData(updatedCartPujaData);
    }
  };

  return (
    <>
      {selectedtemplemode ? (
        <>
          {selectedtemplemode === "Online" ? (
            <div className="w-full flex flex-col justify-between my-4 gap-4 cursor-pointer">
              {allpackages.map((singlepackage) => {
                const { packageId, name, packagePrice } = singlepackage;
                const isSelected = carttemplepujadata.some(
                  (cartpackage) =>
                    cartpackage.pujas[0].pujaId === pujaId &&
                    cartpackage.pujas[0].homePuja.packageId === packageId
                );

                return (
                  <div
                    key={packageId}
                    className={`w-full flex flex-row justify-between items-center border-2 border-[#000000] text-[16px] font-semibold px-10 py-4 gap-10 rounded-[10px] ${
                      isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
                    }`}
                    onClick={() => handleselectedpackage(singlepackage)}
                  >
                    <div className="text-[20px] max-md:text-[16px] font-inter font-bold text-[#000000]">
                      {name[0]}
                    </div>
                    <div className="flex flex-row gap-4">
                      <div className="text-[20px] max-md:text-[16px] font-inter font-bold text-[#FF8213]">
                        ₹ {packagePrice}
                      </div>
                      <div>
                        {isSelected && (
                          <button
                            onClick={() => {
                              setOpenpackagemodal(true);
                            }}
                            className="text-[16px] font-semibold text-[#FF8213]"
                          >
                            Know More
                          </button>
                        )}
                      </div>
                      <button
                        onClick={() => {
                          setOpenpackagemodal(true);
                        }}
                      >
                        <img src={info} alt="info" className="size-6" />
                      </button>
                    </div>
                  </div>
                );
              })}
              <div>
                <span
                  onClick={() => setPackageQueryModal(true)}
                  className="text-[18px] max-md:text-[16px] font-inter font-bold text-[#FF8213]"
                >
                  Not Sure?
                </span>
                <div>
                  <PackageQueryModal name={name} />
                </div>
              </div>
              {selectedpackage ? (
                <PackageDetailModal {...{ selectedpackage, name }} />
              ) : null}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-between my-4 gap-4 cursor-pointer">
              {allpackages.map((singlepackage) => {
                const { packageId, name, packagePrice } = singlepackage;

                const isSelected = carttemplepujadata.some(
                  (cartpackage) =>
                    cartpackage.pujas[0].pujaId === pujaId &&
                    cartpackage.pujas[0].homePuja.packageId === packageId
                );

                return (
                  <div
                    key={packageId}
                    className={`w-full flex flex-row justify-between items-center border-2 border-[#000000] text-[16px] font-semibold px-10 py-4 gap-10 rounded-[10px] ${
                      isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
                    }`}
                    onClick={() => handleselectedpackage(singlepackage)}
                  >
                    <div className="text-[20px] max-md:text-[16px] font-inter font-bold text-[#000000]">
                      {name[0]}
                    </div>
                    <div className="flex flex-row gap-4">
                      <div className="text-[20px] max-md:text-[16px] font-inter font-bold text-[#FF8213]">
                        ₹ {packagePrice}
                      </div>
                      <div>
                        {isSelected && (
                          <button
                            onClick={() => {
                              setOpenpackagemodal(true);
                            }}
                            className="text-[16px] font-semibold text-[#FF8213]"
                          >
                            Know More
                          </button>
                        )}
                      </div>
                      <button
                        onClick={() => {
                          setOpenpackagemodal(true);
                        }}
                      >
                        <img src={info} alt="info" className="size-6" />
                      </button>
                    </div>
                  </div>
                );
              })}
              <div>
                <span
                  onClick={() => setPackageQueryModal(true)}
                  className="text-[18px] max-md:text-[16px] font-inter font-bold text-[#FF8213]"
                >
                  Not Sure?
                </span>
                <div>
                  <PackageQueryModal name={name} />
                </div>
              </div>
              {selectedpackage ? (
                <PackageDetailModal {...{ selectedpackage, name }} />
              ) : null}
            </div>
          )}
        </>
      ) : null}
    </>
  );
}

export default TemplePujaPackageSelection;
