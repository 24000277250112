import React, { useState, useEffect, useContext } from "react";
import { ContextData } from "../../context/Context";

function AnnouncementBanner() {
  const [isVisible, setIsVisible] = useState(true);
  const [bannerText, setBannerText] = useState(
    "✨ Experience the Divine! Puja and Mahakumbh services are now available. Reserve today!"
  );

  const { setServiceSelectionModal } = useContext(ContextData);

  const updateBannerText = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 768) {
      setBannerText("✨ Puja & Mahakumbh services are live. Book now!");
    } else if (screenWidth <= 1024) {
      setBannerText("✨ Divine services are live. Reserve now!");
    } else {
      setBannerText(
        "✨ Experience the Divine! Puja and Mahakumbh services are now available. Reserve today!"
      );
    }
  };

  useEffect(() => {
    updateBannerText();
    window.addEventListener("resize", updateBannerText);

    return () => {
      window.removeEventListener("resize", updateBannerText);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <div className="max-sm:flex max-sm:flex-row max-sm:gap-8 max-sm:items-start w-full bg-gradient-to-r from-[#FFF7E6] to-[#F4ECFF] border-b border-gray-300 shadow-sm flex items-center justify-evenly px-4 py-3 relative">
      <div className="w-full flex flex-row max-sm:flex-col justify-center items-center gap-4 text-center animate-slidein">
        <p className="text-sm sm:text-base font-medium text-gray-800">
          {bannerText}{" "}
          <span className="text-red-500 font-bold">Reserve today!</span>
        </p>
        <button
          onClick={() => setServiceSelectionModal(true)}
          className="bg-blue-600 hover:bg-blue-800 text-white text-xs sm:text-sm font-semibold px-4 py-2 rounded-lg shadow-lg transition-all"
        >
          Book Now
        </button>
      </div>
      <button
        onClick={() => setIsVisible(false)}
        className="flex flex-end text-gray-600 hover:text-gray-800 text-lg focus:outline-none transition-transform hover:rotate-90"
        aria-label="Close announcement"
      >
        ✕
      </button>
    </div>
  );
}

export default AnnouncementBanner;
