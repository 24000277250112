import React from "react";
import Button from "../button/Button";
import LazyLoad from "react-lazyload";

function LandingPageMainCard({
  name,
  templeLocation,
  pujaImageURL,
  description,
  buttonlabel,
  time,
  onClick,
}) {
  const pujaImage = pujaImageURL[0].toLowerCase();
  const pujaimagearray = pujaImage.split("/");
  const imageurl = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;

  const aboutpara = description.split(".");

  return (
    <div
      className="h-min border border-2 border-[#CCA7FF] flex flex-row gap-4 rounded-[20px] cursor-pointer"
      style={{ background: "linear-gradient(90deg, #E2D1F9 55.5%, #FFF 100%)" }}
      onClick={onClick}
    >
      <LazyLoad height={100} offset={100}>
        <img
          src={imageurl}
          alt={name}
          className="w-[350px] h-min rounded-l-[20px]"
        />
      </LazyLoad>
      <div className="flex flex-col justify-center gap-2 px-4 py-2">
        <h1 className="text-[22px] font-sans non-italic font-bold text-[#000000]">
          {name}
        </h1>
        {templeLocation ? (
          <h1 className="text-[16px] font-sans non-italic font-bold text-[#FF8213]">
            {templeLocation}
          </h1>
        ) : null}
        <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4">
          {`${aboutpara[0]}.`}
        </p>

        {time ? (
          <div className="w-full flex justify-between items-center max-md:hidden">
            <span className="text-[#595959] font-sans text-[16px] non-italic font-bold">
              {time}
            </span>
            <div>
              <Button
                label={buttonlabel}
                style={{ backgroundColor: "#FFF1D0", color: "black" }}
                onClick={onClick}
              />
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-end max-md:hidden">
            <div>
              <Button
                label={buttonlabel}
                style={{ backgroundColor: "#FFF1D0", color: "black" }}
                onClick={onClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LandingPageMainCard;
