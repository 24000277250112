import React, { useContext, useEffect } from "react";
import ProgressSteps from "../../components/progresssteps/ProgressSteps";
import { ContextData } from "../../context/Context";
import SearchCategory from "./sections/category/sections/SearchCategory";
import NavBar from "../../components/navbar/NavBar";

function ProductPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { currentpuja } = useContext(ContextData);
  return (
    <>
      <NavBar />
      <div>{currentpuja === 0 ? <SearchCategory /> : null}</div>
      <ProgressSteps />
    </>
  );
}

export default ProductPage;
