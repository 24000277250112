import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";
import MahakumbhOrderCard from "../../../../../components/cards/MahakumbhOrderCard";

function OrdersMahakumbh() {
  const { mahakumbhorderlist } = useContext(ContextData);

  return (
    <>
      <div className="flex flex-row max-md:flex-col justify-between max-md:gap-4">
        <h1 className="font-inter text-[32px] max-md:text-[24px] font-bold text-[#000000] mb-10 max-md:mb-5">
          Your Orders
        </h1>
        {/*<SearchOrder />*/}
      </div>
      {mahakumbhorderlist && mahakumbhorderlist.length !== 0 ? (
        <div className="flex flex-col gap-8">
          {mahakumbhorderlist.map((order) => {
            const { orderId } = order;

            return (
              <div key={orderId}>
                <MahakumbhOrderCard order={order} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            No Orders
          </div>
        </div>
      )}
    </>
  );
}

export default OrdersMahakumbh;
