import React, { useContext, useEffect } from "react";
import { ContextData } from "../../../../../context/Context";
import categorypagemainimage from "../../../../../assets/disposableassets/categorypagemainimage.webp";
import CategoryPageCard from "../../../../../components/cards/CategoryPageCard";
import LazyLoad from "react-lazyload";
import Button from "../../../../../components/button/Button";
import { Link } from "react-router-dom";

function AllPujaList() {
  const {
    pujasummary,
    pujasummarycopy,
    setCurrentPuja,
    setCurrentpujadetail,
    setCartPujaData,
    setGods,
    setSearchPujaQuery,
    setselectedmode,
    setselectedpackage,
    setSpecialRequirement,
  } = useContext(ContextData);

  useEffect(() => {
    const gods = [
      ...new Set(pujasummary.map((puja) => puja.god[0]).filter((god) => god)),
    ];
    setGods(gods);
  }, [setGods, pujasummary]);

  const handlepuja = async (pujaId, pujaImageURL) => {
    setCurrentPuja(1);
    try {
      const response = await fetch(
        "https://app.ritividhi.com/ritividhi/offline-puja"
      );
      const currentpuja = await response.json();
      const currentpujadata = currentpuja.filter(
        (puja) => puja.pujaId === pujaId
      );

      if (currentpujadata.length > 0) {
        const [pujaDetails] = currentpujadata;
        const updatedAddOns = pujaDetails.addOns.map((addOn) => ({
          ...addOn,
          quantity: 0,
        }));

        const pujadetailWithImage = {
          ...pujaDetails,
          pujaImageURL,
          addOns: updatedAddOns,
        };

        setSearchPujaQuery("");
        setSpecialRequirement("");
        setselectedmode("");
        setselectedpackage("");
        setCurrentpujadetail([pujadetailWithImage]);
        setCartPujaData([pujadetailWithImage]);
      }
    } catch (error) {
      console.error("Error fetching all pujas summary", error);
    }
  };

  return (
    <>
      <div className="px-[8%] py-10">
        <div className="flex flex-col gap-4 max-lg:hidden">
          <Link to="/kumbh">
            <div
              className="h-min border border-2 border-[#CCA7FF] flex flex-row justify-between pr-10 gap-4 rounded-[20px] cursor-pointer"
              style={{
                background: "linear-gradient(90deg, #E2D1F9 55.5%, #FFF 100%)",
              }}
            >
              <div className="flex justify-center items-center">
                <LazyLoad offset={100}>
                  <img
                    src={categorypagemainimage}
                    alt="Visual representation of Puja"
                    className="w-[550px] h-[300px] rounded-[16px] max-md:w-auto max-md:h-auto max-md:rounded-l-[0px] max-md:rounded-b-[16px]"
                  />
                </LazyLoad>
              </div>

              <div className="flex flex-col justify-center gap-2 px-4 py-2">
                <h1 className="text-[22px] font-sans non-italic font-bold text-[#000000] text-center">
                  🌟 Limited Time Offer: Book Mahakumbh Puja Today! 🌟
                </h1>
                <h2 className="text-[18px] font-sans non-italic font-bold text-[#000000] text-center">
                  🙏 Unlock Divine Blessings with a 15% Discount! 
                </h2>
                <div className="flex flex-row">
                  <div>
                    <ul className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-2 block max-sm:hidden">
                      Experience the grandeur of Mahakumbh Puja with authentic
                      rituals performed by trained Vedic pandits.
                      <li className="font-semibold">
                        ✅ Flat 15% Off on all bookings.{" "}
                      </li>
                      <li className="font-semibold">
                        ✅ Bonus Gift: Get a free astrology consultation.{" "}
                      </li>
                      <li className="font-semibold">
                        ✅ Tailored Rituals for your spiritual journey.
                      </li>
                    </ul>
                    <div>
                      📅 Offer valid until{" "}
                      <span className="font-semibold">February 26, 2025.</span>
                    </div>
                    <div>
                      📲 Book Now to make your sacred moments truly special
                    </div>
                  </div>
                  <div className="self-end max-lg:self-start max-md:self-end">
                    <Button
                      label="Book Now"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div className="grid grid-cols-3 gap-4">
            {pujasummarycopy.map((puja) => {
              const {
                pujaId,
                name,
                pujaImageURL,
                description,
                buttonlabel = "Book Now",
                time,
              } = puja;
              return (
                <div key={pujaId}>
                  <CategoryPageCard
                    name={name}
                    pujaImageURL={pujaImageURL}
                    description={description}
                    buttonlabel={buttonlabel}
                    time={time}
                    onClick={() => handlepuja(pujaId, pujaImageURL)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="hidden max-lg:flex max-lg:flex-col max-lg:gap-8">
          <div className="grid grid-cols-2 gap-4">
            {pujasummarycopy.map((puja) => {
              const {
                pujaId,
                name,
                pujaImageURL,
                description,
                buttonlabel = "Book Now",
                time,
              } = puja;
              return (
                <div key={pujaId}>
                  <CategoryPageCard
                    name={name}
                    pujaImageURL={pujaImageURL}
                    description={description}
                    buttonlabel={buttonlabel}
                    time={time}
                    onClick={() => handlepuja(pujaId, pujaImageURL)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllPujaList;
