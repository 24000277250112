import React, { useContext, useEffect } from "react";
import Footer from "../../../../components/footer/Footer";
import { Breadcrumb } from "antd";
import NavBar from "../../../../components/navbar/NavBar";
import { ContextData } from "../../../../context/Context";
import MahaKumbhTravellersDetails from "./sections/mahakumbhorderdetails/MahaKumbhTravellersDetails";
import MahaKumbhPaymentCard from "./sections/mahakumbhorderdetails/MahaKumbhPaymentCard";
import MahaKumbhSummaryCard from "./sections/mahakumbhorderdetails/MahaKumbhSummaryCard";

function MahaKumbhOrderDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { orderdetail } = useContext(ContextData);

  let updatedorderdate = orderdetail.orderDate.split("T");

  return (
    <>
      <NavBar />
      <div className="px-[16%] max-md:px-[8%] py-5 flex flex-col gap-4">
        <Breadcrumb
          items={[
            {
              title: (
                <a
                  href="/"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Home
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/user"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Profile
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/order"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Order History
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/orderdetail"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Order Details
                </a>
              ),
            },
          ]}
          className="mb-5"
        />
        <h1 className="text-[28px] max-md:text-[24px] font-inter text-[#000000] font-bold">
          Order Details
        </h1>
        <>
          <div className="mb-4 text-[18px] max-md:text-[12px] font-inter text-[#000000] font-semibold flex flex-row max-md:flex-col justify-between">
            <span>Order placed on {updatedorderdate[0]}</span>
            <div className="flex flex-row gap-2">
              <span>Order ID :</span>
              <span>{orderdetail.orderId}</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <MahaKumbhSummaryCard order={orderdetail} />
            <MahaKumbhPaymentCard order={orderdetail} />
            <MahaKumbhTravellersDetails order={orderdetail} />
          </div>
        </>
      </div>
      <Footer />
    </>
  );
}

export default MahaKumbhOrderDetail;
