import React, { useContext } from "react";
import { ContextData } from "../../../../context/Context";
import TemplePujaCard from "./sections/TemplePujaCard";
import Button from "../../../../components/button/Button";
import Footer from "../../../../components/footer/Footer";

function TemplePujas() {
  const { currenttempledetail, prevtemplestep } = useContext(ContextData);

  return (
    <>
      <div className="px-[8%] py-10">
        <div className="my-5">
          <Button
            label="Previous"
            style={{
              backgroundColor: "transparent",
              color: "black",
              borderColor: "#FF8213",
            }}
            onClick={() => prevtemplestep()}
          />
        </div>
        <div>
          {currenttempledetail.length > 0 && currenttempledetail ? (
            <>
              {currenttempledetail.map((temple) => {
                const { templeId, templeName, templeLocation, pujas } = temple;
                return (
                  <div key={templeId} className="flex flex-col">
                    <div className="my-5 flex flex-col gap-2">
                      <h1 className="text-[28px] font-sans font-bold text-[#000000]">
                        Book Puja at {templeName}
                      </h1>
                      <div className="text-[18px] max-md:text-[14px] font-bold">
                        Location :{" "}
                        <span className="text-[#FF8213]">{templeLocation}</span>
                      </div>
                    </div>
                    <TemplePujaCard
                      pujas={pujas}
                      templeName={templeName}
                      templeLocation={templeLocation}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <div className="h-full">
              <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
                Loading your Pujas...
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TemplePujas;
