import React, { useContext } from "react";
import GroupPackageCard from "../../../../../components/cards/GroupPackageCard";
import { ContextData } from "../../../../../context/Context";
import GroupPujaUserDetailModal from "../../../../../components/modals/GroupPujaUserDetailModal";

function GroupPujaPackageSelection({ grouppujapackges }) {
  const {
    selectedgrouppackage,
    setSelectedgrouppackage,
    setOpendetailnmodal,
    cartgrouppujadata,
    setCartGroupPujaData,
    setCurrentgrouptempledetail,
  } = useContext(ContextData);

  let allpackagesavailable = grouppujapackges[0].participationOption;

  const handlepackage = (optionId) => {
    const selectedpackage = allpackagesavailable.find(
      (allpackage) => allpackage.optionId === optionId
    );

    setSelectedgrouppackage(selectedpackage || null);

    const updatedcartgrouppujadata = cartgrouppujadata.map((groupdata) => {
      const { liveEvents } = groupdata;
      const updatedTemples = liveEvents.map((temple, index) => {
        if (index === 0) {
          return {
            ...temple,
            participationOption: [selectedpackage],
          };
        }
        return temple;
      });

      setCurrentgrouptempledetail(updatedTemples);

      return {
        ...groupdata,
        liveEvents: updatedTemples,
      };
    });

    setCartGroupPujaData(updatedcartgrouppujadata);
  };

  return (
    <>
      <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20 flex flex-col">
        <h1 className="mb-10 text-[20px] max-md:text-[18px] font-inter font-bold text-[#000000]">
          Select Puja Package
        </h1>
        <div className="grid grid-cols-4 max-md:grid-cols-2 gap-4 max-md:gap-2">
          {allpackagesavailable.map((packageinfo) => {
            const { optionId, name, price } = packageinfo;
            const isSelected = selectedgrouppackage?.optionId === optionId;
            return (
              <div
                key={optionId}
                className={`flex flex-col justify-between items-center gap-8 py-3 px-4 ${
                  isSelected
                    ? "bg-[#E2D1F9] border-2 border-[#000000]"
                    : "bg-[#faecdf] opacity-40 border-2 border-[#000000]"
                } rounded-[10px] text-[20px] max-md:text-[18px] font-inter font-bold text-[#000000]`}
                onClick={() => handlepackage(optionId)}
              >
                <span>{name}</span>
                <span>₹ {price}</span>
              </div>
            );
          })}
        </div>
        <div>
          {selectedgrouppackage && selectedgrouppackage.name ? (
            <div className="my-10 pt-5 bg-[#faecdf] rounded-[10px]">
              <div className="flex flex-col gap-8 h-full">
                <span className="text-[20px] max-md:text-[18px] font-inter font-bold text-[#000000] px-8">
                  {selectedgrouppackage.name}
                </span>
                <span className="text-[18px] max-md:text-[18px] font-inter font-bold text-justify text-[#000000] px-8">
                  {selectedgrouppackage.description}
                </span>
                <span className="text-[18px] max-md:text-[16px] font-inter font-normal text-justify text-[#000000] px-8">
                  <GroupPackageCard
                    packagedescription={selectedgrouppackage.includes}
                  />
                </span>
                <button
                  onClick={() => setOpendetailnmodal(true)}
                  className="w-full py-6 flex justify-center text-[18px] max-md:text-[16px] font-bold font-sans text-[#000000] bg-[#E6D8FA] rounded-b-[10px]"
                >
                  Proceed with {selectedgrouppackage.name}
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full py-6 my-4 flex justify-center items-center text-[18px] max-md:text-[16px] font-bold font-sans text-[#000000] bg-[#faecdf] rounded-[10px]">
              Select any package
            </div>
          )}
        </div>
      </div>
      <GroupPujaUserDetailModal />
    </>
  );
}

export default GroupPujaPackageSelection;
