import React from "react";
import templeactualimage from "../../../../../assets/disposableassets/templeactualimage.webp";

function TempleDescription({ liveEvents }) {
  return (
    <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20 flex flex-col justify-between">
      <h1 className="text-[20px] max-md:text-[18px] font-bold font-inter text-[#00000] mb-10">
        {liveEvents[0].templeName}
      </h1>
      <div className="flex flex-row max-md:flex-col gap-8 rounded-[10px]">
        <img
          src={templeactualimage}
          alt="templeactualimage"
          className="w-1/2 rounded-[10px] max-md:w-full"
        />
        <div className="text-[18px] max-md:text-[16px] text-justify font-normal font-inter text-[#00000]">
          {liveEvents[0].aboutTemple}
        </div>
      </div>
    </div>
  );
}

export default TempleDescription;
