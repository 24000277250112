import React, { useContext } from "react";
import Button from "../button/Button";
import { ContextData } from "../../context/Context";
import ContactUsModal from "../modals/ContactUsModal";

function ServiceContact() {
  const { setContactUsModal } = useContext(ContextData);

  return (
    <>
      <div
        className="flex flex-col justify-between border border-2 border-[#B8F3DE] rounded-[20px] p-8 max-xl:h-full"
        style={{
          background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
        }}
      >
        <div className="flex flex-col h-full">
          <h1 className="font-sans text-[20px] not-italic font-bold text-[#000000] mb-4">
            Not sure? Discover the perfect Puja tailored for you
          </h1>
          <p className="font-sans text-[16px] not-italic font-bold text-[#000000] mb-4">
            Perfect Puja is waiting for you
          </p>
        </div>
        {/* <div className="flex flex-row max-lg:flex-col max-md:flex-row justify-between gap-2">
        <div className="flex flex-col gap-4 w-full">
          <div className="relative w-full">
            <input
              type="email"
              placeholder="example@username.com"
              value={queryemail}
              onChange={handleEmailChange}
              className={`w-full border rounded-[10px] px-4 py-1 ${
                emailError
                  ? "border-red-500 bg-[#FFE6E6]"
                  : "border-[#938D8D] bg-[#FFF1D0]"
              } placeholder:text-[16px] placeholder:font-sans placeholder:italic placeholder:text-[#7B7575]`}
            />
            {emailError && (
              <span className="text-red-500 text-sm mt-1">{emailError}</span>
            )}
          </div>
          <div className="font-sans text-[14px] not-italic font-semibold text-[#000000]">
            Puja Name
          </div>
          <input
            type="text"
            placeholder="Your short message"
            value={querymessage}
            onChange={(e) => setquerymessage(e.target.value)}
            className="w-full border border-[#938D8D] rounded-[10px] bg-[#FFF1D0] px-4 py-1 placeholder:text-[16px] placeholder:font-sans placeholder:italic placeholder:text-[#7B7575]"
          />
          <div className="font-sans text-[14px] not-italic font-semibold text-[#000000]">
            Kindly specify the puja name and your query
          </div>
          <input
            type="text"
            placeholder="Your short message"
            value={querymessage}
            onChange={(e) => setquerymessage(e.target.value)}
            className="w-full border border-[#938D8D] rounded-[10px] bg-[#FFF1D0] px-4 py-1 placeholder:text-[16px] placeholder:font-sans placeholder:italic placeholder:text-[#7B7575]"
          />
          <div className="self-end max-lg:self-start max-md:self-end">
            <Button
              label="Contact"
              style={{
                backgroundColor: "#FFF1D0",
                color: "black",
                borderColor: "#000000",
              }}
              disabled={!!emailError || !queryemail}
              onClick={() => handlemessage()}
            />
          </div>
        </div>
      </div> */}
        <div className="self-start">
          <Button
            label="Contact Us"
            style={{
              backgroundColor: "#FFF1D0",
              color: "black",
              borderColor: "#000000",
            }}
            onClick={() => setContactUsModal(true)}
          />
        </div>
      </div>
      <ContactUsModal />
    </>
  );
}

export default ServiceContact;
