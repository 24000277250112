import React from "react";
import Button from "../../../../../../components/button/Button";

function PujaPaymentCard({ order }) {
  return (
    <>
      <div className="border border-[#D9D9D9] rounded-[10px] flex flex-col gap-2 bg-[#fff1cf]">
        <div className="flex flex-row justify-between items-center px-16 py-4">
          <div className="flex flex-col gap-4">
            <span className="text-[20px] max-md:text-[14px] font-inter text-[#000000] font-bold">
              Payment Details
            </span>
            {order.paymentMethod === "UPI" ? (
              <span className="text-[18px] max-md:text-[16px] font-inter text-[#067841] font-semibold">
                UPI
              </span>
            ) : (
              <span className="text-[18px] max-md:text-[16px] font-inter text-[#ff4d4f] font-semibold">
                No Payment Method
              </span>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
              Payment Status
            </span>
            {order.status === "SUCCESS" ? (
              <span className="text-[18px] max-md:text-[16px] font-inter text-[#067841] font-semibold">
                Paid
              </span>
            ) : (
              <span className="text-[18px] max-md:text-[16px] font-inter text-[#ff4d4f] font-semibold">
                Pending
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 flex-1 px-16 py-4">
          <div className="flex flex-row justify-between items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-semibold text-[#000000]">
              Puja
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              ₹ {order.selectedPackage.packagePrice}
            </span>
          </div>
          <div className="flex flex-row justify-between items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-semibold text-[#000000]">
              Add Ons
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              ₹ {order.totalPrice - order.selectedPackage.packagePrice}
            </span>
          </div>
          <div className="flex flex-row justify-between items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-semibold text-[#000000]">
              Total
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              ₹ {order.totalPrice}
            </span>
          </div>
        </div>
        {/* <div className="px-16 py-4 flex justify-end w-full">
          <Button
            label="Download Invoice"
            style={{
              backgroundColor: "tansparent",
              color: "black",
              borderColor: "black",
            }}
          />
        </div> */}
      </div>
    </>
  );
}

export default PujaPaymentCard;
